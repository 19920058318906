export default {
    rm: {
        b: {
            normal: '正常',
            violate: '违规',
            multNormal: '批量正常',
        },
        t: {
            c: {
                reportId: '举报ID',
                postUid: '帖子ID',
                formPostUid: '所属帖子ID',
                commentId: '评论ID',
                commentContent: '评论内容',
                postAccUid: '发布者UID',
                content: '正文',
                postImg: '图片/视频',
                postAudio: '音频',
                nickName: '昵称',
                name: '用户名',
                avatar: '头像',
                userInfo: '个人简介',
                reportType: '举报类型',
                otherReason: '其他原因',
                reportTime: '举报时间',
                'reporter-UID': '举报者UID',
                'review-result': '审核结果',
                debate: '辩题',
                debateUsers: '正方&反方',
                shortsId: '短视频ID',
                video: '视频',
                formShortsId: '所属短视频ID',
                image: '图片',
                title: '标题',
            },
        },
        reportType: {
            other: '其他原因',

            Bullying: '欺凌和骚扰',
            HateSpeech: '仇恨言论和宣扬仇恨思想',
            Scam: '骗局或欺诈信息',
            Spam: '垃圾信息',
            Racism: '种族主义',
            Nudity: '裸露或性内容',
            PlatformSecurity: '平台安全',
            FalseInformantion: '虚假信息',
            Violent: '暴力或危险组织',
            IntellectualPropertyRights: '侵犯知识产权',
            Other: '其他原因',
        },
        p: {},
    },
};
