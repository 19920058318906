/**
 * Menu
 */
export default {
    m: {
        /**
         * Home
         *
         * 首页
         */
        Home: 'Home',

        /**
         * Content Moderation
         *
         * 内容审核
         */
        'Content-Moderation': 'Content Moderation',

        'Content-Moderation_Automated-Moderation': 'Automated Moderation',

        'Content-Moderation_Automated-Moderation_Post': 'Post',

        'Content-Moderation_Automated-Moderation_Shorts': 'Shorts',

        'Content-Moderation_Automated-Moderation_Memecoin': 'Memecoin',

        'Content-Moderation_Content-Moderation': 'Content Moderation',

        'Content-Moderation_Content-Moderation_Post': 'Posts',

        'Content-Moderation_Content-Moderation_Shorts': 'Shorts',

        'Content-Moderation_Content-Moderation_Memecoin': 'Memecoin',

        'Content-Moderation_Strategy-Config': 'Strategy Config',

        'Content-Moderation_Strategy-Config_Automated-Config': 'Automated Config',

        'Content-Moderation_Moderation-Records': 'Moderation Records',

        'Content-Moderation_Moderation-Records_Post': 'Posts',

        'Content-Moderation_Moderation-Records_Memecoin': 'Memecoin',

        'Content-Moderation_Moderation-Records_Shorts': 'Shorts',

        /**
         * Reports
         *
         * 举报管理
         */
        reports: 'Reports',

        'report-moderation': 'Report Moderation',

        'moderation-records': 'Moderation Records',
        rm: {
            posts: 'Posts',
            'posts-comments': 'Posts Comments',
            shorts: 'Shorts',
            'shorts-comments': 'Shorts Comments',
            profiles: 'Profile',
        },

        /**
         * System Management
         *
         * 系统管理
         */
        'System-Management': 'System Management',

        'System-Management_Accounts': 'Accounts',

        'System-Management_Roles': 'Roles',

        'System-Management_Logs': 'Logs',

        'System-Management_Exports': 'Downloads',
    },
};
