import { useRef } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, Modal, Spin, Image } from 'antd';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { get } from 'lodash';
import { ProTable } from '@ant-design/pro-components';

/**
 * APIs
 */
import { changeUserNormalApi, changeUserReportStatusApi, profileReportApi, Status } from '@/services/report';

/**
 * Components
 */
import Permission from '@/components/permission';
import ComponentAccountReportBtn from './components/account-report-btn';
import ComponentTableCellReason from '../components/table-cell-reason';

/**
 * Constants
 */
const WEB2_ASSETS_URL = import.meta.env.VITE_WEB2_ASSETS || '';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { ProfileReportResult } from '@/services/report';
// import type { IntlShape } from 'react-intl';

type TableParams = {
    user_id: string;
    pageSize: number;
    current: number;
};

type SeletRowState = {
    selects: string[];
    loading: boolean;
};

/**
 * Constants
 */
import ReportTypeENUM from '../components/report-type-enum';

const PageProfileReport: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    /**
     * States
     */
    const intl: IntlShape = useIntl();
    const tableRef = useRef<ActionType>();
    const [selectState, setSelectState] = useSetState<SeletRowState>({
        selects: [],
        loading: false,
    });
    const [tableCellNormalLoading, setTableCellNormalLoading] = useSetState<Record<string, boolean>>({});
    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Requests
     */
    const { run: runChangeUserNormalApi } = useRequest(changeUserNormalApi, {
        manual: true,
        onBefore: () => {
            setSelectState({
                loading: true,
            });
        },
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            } else {
                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
            }
            tableRef.current?.reload();
        },
        onError: (e) => {
            message.error(get(e, 'response.data.msg', 'Error'));
        },
        onFinally: () => {
            setSelectState({
                loading: false,
                selects: [],
            });
        },
    });

    // 个人资料重新Api
    const { run: runChangeUserReportStatusApi } = useRequest(changeUserReportStatusApi, {
        manual: true,
        onBefore: (params) => {
            setTableCellViolateLoading({
                [params[0]]: true,
            });
        },
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }
            tableRef.current?.reload();
        },
        onFinally: (params) => {
            setTableCellViolateLoading({
                [params[0]]: false,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const proTableProps: ProTableProps<ProfileReportResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.reportId" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'user_id'],
                ellipsis: true,
                title: 'UID',
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'nickname'],
                title: <FormattedMessage id="rm.t.c.nickName" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'username'],
                title: <FormattedMessage id="rm.t.c.name" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'avatar'],
                title: <FormattedMessage id="rm.t.c.avatar" />,
                render: (_, entity) => {
                    const avatarSrc = get(entity, 'content.reportedUserInfo.avatar');
                    if (!avatarSrc) {
                        return '-';
                    }
                    return <Image src={`${WEB2_ASSETS_URL}/${avatarSrc}`} width={40} />;
                },
                width: 80,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'bio'],
                title: <FormattedMessage id="rm.t.c.userInfo" />,
            },
            {
                dataIndex: 'report_type',
                title: <FormattedMessage id="rm.t.c.reportType" />,
                width: 120,
                valueEnum: ReportTypeENUM,
            },
            {
                dataIndex: 'reason',
                title: <FormattedMessage id="rm.t.c.otherReason" />,
                render: (_, entity) => <ComponentTableCellReason type={entity.report_type} reason={entity.reason} />,
                width: 200,
            },
            {
                dataIndex: 'created_at',
                width: 200,
                valueType: 'dateTime',
                title: <FormattedMessage id="rm.t.c.reportTime" />,
                hideInSearch: true,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    <Permission permission="Reports_Report-Moderation_Profiles" key="normal">
                        <Spin spinning={!!tableCellNormalLoading[entity.id]} size="small">
                            <a
                                key="normal"
                                onClick={() => {
                                    setTableCellNormalLoading({
                                        [entity.id]: true,
                                    });
                                    changeUserNormalApi({
                                        ids: [entity.id],
                                        status: Status.Normal,
                                    })
                                        .then(({ data: { code, msg } }) => {
                                            if (code === 0) {
                                                message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                tableRef.current?.reload();
                                            } else {
                                                message.error(msg || 'Error');
                                            }
                                            setTableCellNormalLoading({
                                                [entity.id]: false,
                                            });
                                        })
                                        .catch((e) => {
                                            message.error(get(e, 'response.data.msg', 'Error'));
                                            setTableCellNormalLoading({
                                                [entity.id]: false,
                                            });
                                        });
                                }}
                            >
                                <FormattedMessage id="rm.b.normal" />
                            </a>
                        </Spin>
                    </Permission>,

                    <Permission permission="Reports_Report-Moderation_Profiles" key="voild">
                        <ComponentAccountReportBtn
                            entity={entity}
                            sureCallback={runChangeUserReportStatusApi}
                            loading={!!tableCellViolateLoading[entity.id]}
                        />
                    </Permission>,
                ],
            },
        ],
        search: false,
        rowKey: 'id',
        rowSelection: {
            columnWidth: 50,
            selectedRowKeys: selectState.selects,
            getCheckboxProps: () => ({
                disabled: selectState.loading,
            }),
            onChange: (selectedRowKeys) => {
                setSelectState({
                    selects: selectedRowKeys as string[],
                    loading: false,
                });
            },
        },
        scroll: {
            y: 'calc(100vh - 272px)',
            x: 1900,
        },
        tableAlertRender: false,
        toolBarRender: () => {
            if (!selectState.selects.length) {
                return [];
            }
            return [
                <Permission permission="Reports_Report-Moderation_Profiles">
                    <Button
                        type="primary"
                        loading={selectState.loading}
                        onClick={() => {
                            Modal.confirm({
                                title: intl.formatMessage({ id: 'c.areyousure' }),
                                onOk: async () => {
                                    setSelectState({
                                        loading: true,
                                    });
                                    runChangeUserNormalApi({
                                        ids: selectState.selects,
                                        status: Status.Normal,
                                    });
                                },
                            });
                        }}
                    >
                        <FormattedMessage id="rm.b.multNormal" />
                    </Button>
                </Permission>,
            ];
        },
        request: async (params: TableParams) => {
            const res = await profileReportApi({
                page: params.current,
                size: params.pageSize,
                status: [Status.Pending],
            });
            return {
                data: get(res, 'data.data.list', []),
                success: get(res, 'data.code') === 0,
                total: get(res, 'data.data.meta.total', 0),
            };
        },
    };

    return <ProTable {...proTableProps} />;
};

export default PageProfileReport;
