import type { TreeDataNode } from 'antd';
import { FormattedMessage } from 'react-intl';

export interface ExtendedTreeDataNode extends TreeDataNode {
    children?: ExtendedTreeDataNode[];
    type?: 'data' | 'action';
    defaultChecked?: string;
    options?: {
        label: React.ReactNode;
        value: string;
    }[];
}

export const permissionData: ExtendedTreeDataNode[] = [
    {
        title: <FormattedMessage id="pm.Content-Moderation" />,
        key: 'Content-Moderation',
        children: [
            {
                title: <FormattedMessage id="pm.Content-Moderation_Content-Moderation" />,
                key: 'Content-Moderation_Content-Moderation',
                children: [
                    {
                        title: <FormattedMessage id="pm.Content-Moderation_Content-Moderation_Post" />,
                        key: 'Content-Moderation_Content-Moderation_Post',
                    },
                    {
                        title: <FormattedMessage id="pm.Content-Moderation_Content-Moderation_Shorts" />,
                        key: 'Content-Moderation_Content-Moderation_Shorts',
                    },
                    {
                        title: <FormattedMessage id="pm.Content-Moderation_Content-Moderation_Memecoin" />,
                        key: 'Content-Moderation_Content-Moderation_Memecoin',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Content-Moderation_Moderation-Records" />,
                key: 'Content-Moderation_Moderation-Records',
                children: [
                    {
                        title: <FormattedMessage id="pm.Content-Moderation_Moderation-Records_Post" />,
                        key: 'Content-Moderation_Moderation-Records_Post',
                    },
                    {
                        title: <FormattedMessage id="pm.Content-Moderation_Moderation-Records_Shorts" />,
                        key: 'Content-Moderation_Moderation-Records_Shorts',
                    },
                    {
                        title: <FormattedMessage id="pm.Content-Moderation_Moderation-Records_Memecoin" />,
                        key: 'Content-Moderation_Moderation-Records_Memecoin',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Content-Moderation_Automated-Moderation" />,
                key: 'Content-Moderation_Automated-Moderation',
                children: [
                    {
                        title: <FormattedMessage id="pm.Content-Moderation_Automated-Moderation_Post" />,
                        key: 'Content-Moderation_Automated-Moderation_Post',
                    },
                    {
                        title: <FormattedMessage id="pm.Content-Moderation_Automated-Moderation_Shorts" />,
                        key: 'Content-Moderation_Automated-Moderation_Shorts',
                    },
                    {
                        title: <FormattedMessage id="pm.Content-Moderation_Automated-Moderation_Memecoin" />,
                        key: 'Content-Moderation_Automated-Moderation_Memecoin',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Content-Moderation_Strategy-Config" />,
                key: 'Content-Moderation_Strategy-Config',
                children: [
                    {
                        title: <FormattedMessage id="pm.Content-Moderation_Strategy-Config_Automated-Config" />,
                        key: 'Content-Moderation_Strategy-Config_Automated-Config',
                    },
                ],
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.Reports" />,
        key: 'Reports',
        children: [
            {
                title: <FormattedMessage id="pm.Reports_Report-Moderation" />,
                key: 'Reports_Report-Moderation',
                children: [
                    {
                        title: <FormattedMessage id="pm.Reports_Report-Moderation_Posts" />,
                        key: 'Reports_Report-Moderation_Posts',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Report-Moderation_Posts-Comments" />,
                        key: 'Reports_Report-Moderation_Posts-Comments',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Report-Moderation_Shorts" />,
                        key: 'Reports_Report-Moderation_Shorts',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Report-Moderation_Shorts-Comments" />,
                        key: 'Reports_Report-Moderation_Shorts-Comments',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Report-Moderation_Profiles" />,
                        key: 'Reports_Report-Moderation_Profiles',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.Reports_Moderation-Records" />,
                key: 'Reports_Moderation-Records',
                children: [
                    {
                        title: <FormattedMessage id="pm.Reports_Moderation-Records_Posts" />,
                        key: 'Reports_Moderation-Records_Posts',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Moderation-Records_Posts-Comments" />,
                        key: 'Reports_Moderation-Records_Posts-Comments',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Moderation-Records_Shorts" />,
                        key: 'Reports_Moderation-Records_Shorts',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Moderation-Records-Comments" />,
                        key: 'Reports_Moderation-Records_Shorts-Comments',
                    },
                    {
                        title: <FormattedMessage id="pm.Reports_Moderation-Records_Profiles" />,
                        key: 'Reports_Moderation-Records_Profiles',
                    },
                ],
            },
        ],
    },
    {
        title: <FormattedMessage id="pm.System-Management" />,
        key: 'System-Management',
        children: [
            {
                title: <FormattedMessage id="pm.System-Management_Accounts" />,
                key: 'System-Management_Accounts',
                children: [
                    {
                        title: <FormattedMessage id="pm.System-Management_Accounts_Create" />,
                        key: 'System-Management_Accounts_Create',
                    },
                    {
                        title: <FormattedMessage id="pm.System-Management_Accounts_Edit" />,
                        key: 'System-Management_Accounts_Edit',
                    },
                    {
                        title: <FormattedMessage id="pm.System-Management_Accounts_Delete" />,
                        key: 'System-Management_Accounts_Delete',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.System-Management_Roles" />,
                key: 'System-Management_Roles',
                children: [
                    {
                        title: <FormattedMessage id="pm.System-Management_Roles_Create" />,
                        key: 'System-Management_Roles_Create',
                    },
                    {
                        title: <FormattedMessage id="pm.System-Management_Roles_Edit" />,
                        key: 'System-Management_Roles_Edit',
                    },
                    {
                        title: <FormattedMessage id="pm.System-Management_Roles_Delete" />,
                        key: 'System-Management_Roles_Delete',
                    },
                    {
                        title: <FormattedMessage id="pm.System-Management_Permissions" />,
                        key: 'System-Management_Permissions',
                    },
                ],
            },
            {
                title: <FormattedMessage id="pm.System-Management_Logs" />,
                key: 'System-Management_Logs',
            },
            {
                title: <FormattedMessage id="pm.System-Management_Exports" />,
                key: 'System-Management_Downloads',
                type: 'data',
                defaultChecked: 'System-Management_Downloads_Personal-Data',
                options: [
                    {
                        label: <FormattedMessage id="pm.System-Management_Exports_Exports_All-Data" />,
                        value: 'System-Management_Downloads_All-Data',
                    },
                    {
                        label: <FormattedMessage id="pm.System-Management_Exports_Exports_Personal-Data" />,
                        value: 'System-Management_Downloads_Personal-Data',
                    },
                ],
            },
        ],
    },
];
