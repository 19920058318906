import { FormattedMessage } from 'react-intl';

const ReportTypeENUM = {
    Bullying: <FormattedMessage id="rm.reportType.Bullying" />,
    HateSpeech: <FormattedMessage id="rm.reportType.HateSpeech" />,
    Scam: <FormattedMessage id="rm.reportType.Scam" />,
    Spam: <FormattedMessage id="rm.reportType.Spam" />,
    Racism: <FormattedMessage id="rm.reportType.Racism" />,
    Nudity: <FormattedMessage id="rm.reportType.Nudity" />,
    PlatformSecurity: <FormattedMessage id="rm.reportType.PlatformSecurity" />,
    FalseInformantion: <FormattedMessage id="rm.reportType.FalseInformantion" />,
    Violent: <FormattedMessage id="rm.reportType.Violent" />,
    IntellectualPropertyRights: <FormattedMessage id="rm.reportType.IntellectualPropertyRights" />,
    Other: <FormattedMessage id="rm.reportType.Other" />,
};

export default ReportTypeENUM;
