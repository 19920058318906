import {
    ModerationLabels,
    ModerationLabelsType,
    ModerationSubLabels,
    PostReviewsMachineEnum,
    PostReviewsSuggestionEnum,
    PostReviewsSuggestionMap,
} from '@/services/reviews';
import { useReviewLabelsStore } from '@/store/review-labels';
import { useIntl } from 'react-intl';

/**
 * Props
 */
type SubLabels = {
    rate: number;
    secondLabel: number;
    subLabel: number;
    subLabelDepth: number;
    thirdLabel: number;
};

type Labels = {
    label: number;
    level: number;
    rate: number;
    subLabels: SubLabels[];
};

type Antispam = {
    label: string;
    secondLabel?: string;
    thirdLabel?: string;
    labels: Labels[];
    suggestion: PostReviewsSuggestionEnum;
};

type Props = {
    type: ModerationLabelsType;
    status: PostReviewsMachineEnum;
    antispam: Antispam;
};

type FormattedLabels = {
    label: string;
    value: number;
    children?: FormattedLabels[];
};

/**
 *
 * @param param0
 * @returns
 */

const formatSubLabels = (labels: ModerationSubLabels[]): FormattedLabels[] => {
    return labels.map((item) => ({
        label: item.name,
        value: Number(item.code),
        children: item.subLabels ? formatSubLabels(item.subLabels) : [],
    }));
};

const formatLabels = (labels: ModerationLabels[]): FormattedLabels[] => {
    return labels.map((item) => ({
        label: item.name,
        value: Number(item.label),
        children: item.subLabels ? formatSubLabels(item.subLabels) : [],
    }));
};

const ComponentTableCellModerationMediaLabel: React.FC<Props> = ({ antispam, type }) => {
    const intl = useIntl();
    if (antispam?.suggestion === PostReviewsSuggestionEnum.normal) {
        return undefined;
    }

    const getLabelText = (antispam: Antispam, type: ModerationLabelsType) => {
        const { labels } = useReviewLabelsStore((state) => ({
            labels: formatLabels(Array.isArray(state.labels?.[type]) ? state.labels?.[type] : []),
        }));

        const formattedLabels: React.ReactNode[] = [];

        const { label, secondLabel, thirdLabel } = antispam;
        const currentLabel = labels.find((item) => item.value === Number(label));
        const secondCurrentLabel = currentLabel?.children?.find((item) => item.value === Number(secondLabel));
        const thirdCurrentLabel = secondCurrentLabel?.children?.find((item) => item.value === Number(thirdLabel));

        if (currentLabel) {
            formattedLabels.push(currentLabel.label);
        }

        if (secondCurrentLabel) {
            formattedLabels.push(secondCurrentLabel.label);
        }

        if (thirdCurrentLabel) {
            formattedLabels.push(thirdCurrentLabel.label);
        }

        return (
            <div
                key={label}
            >{`${formattedLabels.join('-')}: ${intl.formatMessage({ id: `cm.status.${PostReviewsSuggestionMap?.[antispam.suggestion] || 'pending'}` })}`}</div>
        );
    };

    return <div>{getLabelText(antispam, type)}</div>;
};

export default ComponentTableCellModerationMediaLabel;
