/**
 * Permission
 */

export default {
    pm: {
        Users: '用户管理',
        Users_Users: '用户管理',
        Users_Usernames: '用户名管理',
        Users_Usernames_Add: '添加',
        Users_Usernames_Import: '导入',
        Users_Usernames_Delete: '删除',
        'Users_Usernames_Bulk-Delete': '批量删除',
        Users_Users_Detail: '详情',
        Users_Users_Export: '导出',
        'Content-Moderation': '内容审核',
        'Content-Moderation_Content-Moderation': '内容审核',
        'Content-Moderation_Content-Moderation_Post': '帖子',
        'Content-Moderation_Content-Moderation_Shorts': '短视频',
        'Content-Moderation_Content-Moderation_Memecoin': 'Memecoin',
        'Content-Moderation_Moderation-Records': '审核记录',
        'Content-Moderation_Moderation-Records_Post': '帖子',
        'Content-Moderation_Moderation-Records_Shorts': '短视频',
        'Content-Moderation_Moderation-Records_Memecoin': 'Memecoin',
        'Content-Moderation_Automated-Moderation': '机审记录',
        'Content-Moderation_Automated-Moderation_Post': '帖子',
        'Content-Moderation_Automated-Moderation_Shorts': '短视频',
        'Content-Moderation_Automated-Moderation_Memecoin': 'Memecoin',
        'Content-Moderation_Strategy-Config': '策略配置',
        'Content-Moderation_Strategy-Config_Automated-Config': '机审配置',
        Reports: '举报管理',
        'Reports_Report-Moderation': '举报审核',
        'Reports_Report-Moderation_Posts': '帖子',
        'Reports_Report-Moderation_Posts-Comments': '帖子评论',
        'Reports_Report-Moderation_Shorts': '短视频',
        'Reports_Report-Moderation_Shorts-Comments': '短视频评论',
        'Reports_Report-Moderation_Profiles': '个人资料',
        'Reports_Moderation-Records': '审核记录',
        'Reports_Moderation-Records_Posts': '帖子',
        'Reports_Moderation-Records_Posts-Comments': '帖子评论',
        'Reports_Moderation-Records_Shorts': '短视频',
        'Reports_Moderation-Records-Comments': '短视频评论',
        'Reports_Moderation-Records_Profiles': '个人资料',
        'System-Management': '系统管理',
        'System-Management_Accounts': '账号管理',
        'System-Management_Accounts_Create': '创建',
        'System-Management_Accounts_Edit': '编辑',
        'System-Management_Accounts_Delete': '删除',
        'System-Management_Roles': '角色管理',
        'System-Management_Roles_Create': '创建',
        'System-Management_Roles_Edit': '编辑',
        'System-Management_Roles_Delete': '删除',
        'System-Management_Permissions': '权限管理',
        'System-Management_Logs': '系统日志',
        'System-Management_Exports': '下载管理',
        'System-Management_Exports_Exports': '导出',
        'System-Management_Exports_Exports_All-Data': '所有数据',
        'System-Management_Exports_Exports_Personal-Data': '个人数据',
    },
};
