import {
    ModerationLabels,
    ModerationLabelsType,
    ModerationSubLabels,
    PostReviewsMachineEnum,
    PostReviewsSuggestionEnum,
    PostReviewsSuggestionMap,
} from '@/services/reviews';
import { useReviewLabelsStore } from '@/store/review-labels';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

/**
 * Props
 */
type SubLabels = {
    rate: number;
    secondLabel: number;
    subLabel: number;
    subLabelDepth: number;
    thirdLabel: number;
};

type Labels = {
    label: number;
    level: number;
    rate: number;
    subLabels: SubLabels[];
};

type Antispam = {
    label: number;
    secondLabel: number;
    thirdLabel: number;
    labels: Labels[];
    suggestion: PostReviewsSuggestionEnum;
};

type Props = {
    type: ModerationLabelsType;
    status: PostReviewsMachineEnum;
    antispam: Antispam;
    id?: string;
};

type FormattedLabels = {
    label: string;
    value: number;
    children?: FormattedLabels[];
};

/**
 *
 * @param param0
 * @returns
 */

const formatSubLabels = (labels: ModerationSubLabels[]): FormattedLabels[] => {
    return labels.map((item) => ({
        label: item.name,
        value: Number(item.code),
        children: item.subLabels ? formatSubLabels(item.subLabels) : [],
    }));
};

const formatLabels = (labels: ModerationLabels[]): FormattedLabels[] => {
    return labels.map((item) => ({
        label: item.name,
        value: Number(item.label),
        children: item.subLabels ? formatSubLabels(item.subLabels) : [],
    }));
};

const ComponentTableCellModerationLabel: React.FC<Props> = ({ antispam, type }) => {
    const intl = useIntl();
    if (isEmpty(antispam?.labels) || antispam?.suggestion === PostReviewsSuggestionEnum.normal) {
        return undefined;
    }

    const getLabelText = (current: Antispam, type: ModerationLabelsType) => {
        const { labels } = useReviewLabelsStore((state) => ({
            labels: formatLabels(Array.isArray(state.labels?.[type]) ? state.labels?.[type] : []),
        }));

        const { label, secondLabel, thirdLabel, suggestion } = current;
        const currentLabel = labels.find((item) => item.value === label);

        if (!currentLabel) {
            return undefined;
        }

        const formattedLabels = [`${currentLabel?.label}`];

        const currentSecondLabel = currentLabel?.children?.find((item) => item.value === Number(secondLabel));

        if (currentSecondLabel) {
            formattedLabels.push(currentSecondLabel.label);

            const currentThirdLabel = currentSecondLabel?.children?.find((item) => item.value === Number(thirdLabel));

            if (currentThirdLabel) {
                formattedLabels.push(currentThirdLabel.label);
            }
        }

        return `${formattedLabels.join('-')}: ${intl.formatMessage({ id: `cm.status.${PostReviewsSuggestionMap[suggestion]}` })}`;
    };

    return <div>{getLabelText(antispam, type) || '-'}</div>;
};

export default ComponentTableCellModerationLabel;
