export default {
    cm: {
        t: {
            c: {
                postUid: 'Post ID',
                shortsUid: 'Shorts ID',
                userId: 'User ID',
                content: 'Content',
                debate: 'Debate',
                title: 'Title',
                debateUsers: 'Agree&Against',
                forText: 'For',
                againstText: 'Against',
                postVideo: 'Video',
                postImg: 'Image/Video',
                postAudio: 'Audio',
                textTag: 'Text Tag',
                imgTag: 'Image Tag',
                audioTag: 'Audio Tag',
                videoTag: 'Video Tag',
                postTime: 'Post Time',
                statusMachine: 'Audit Result',
                actionMethod: 'Action Method',
                result: 'Audit Result',
                violationType: 'Violation Type',
                statusExecution: 'Execution Result',
                type: 'Scene',
                normal: 'Normal',
                suspected: 'Suspected',
                rejected: 'Rejected',
                failure: 'Failure',
                name: 'Name',
                ticker: 'Ticker',
                description: 'Description',
                group: 'Group',
                image: 'Image',
                x: 'X',
                telegram: 'Telegram',
                website: 'Website',
                reason: 'Reason',
            },
            s: {
                text: 'Text',
                image: 'Image',
                audio: 'Audio',
                video: 'Video',
            },
        },
        status: {
            approved: 'Normal',
            rejected: 'Violation',
            failure: 'Failure',
            pending: 'Pending',
            suspected: 'Suspected',
        },
        executionResult: {
            approved: 'Approved',
            rejected: 'Rejected',
            manual: 'Manual',
            failure: 'Failure',
        },
        violationType: {
            politicsReligion: 'Politic & Religion',
            pornography: 'Pornography',
            terrorismViolence: 'Terrorism & Violence',
            violenceGore: 'Violence & Gore',
            minorProtection: 'Minor Protection',
            controversialSpeech: 'Controversial Speech',
            falseFraud: 'False Fraud',
            illegalControversialContent: 'Illegal & Controversial Content',
            privacyProtection: 'Privacy Protection',
            trafficPromotion: 'Traffic & Promotion',
        },
        type: {
            post: 'Post',
            comment: 'Comment',
            message: 'Message',
            shorts: 'Shorts',
            user: 'User',
            postComment: 'Post Comment',
            shortsComment: 'Shorts Comment',
        },
        reviewsResult: {
            approved: 'Approved',
            rejected: 'Rejected',
            manual: 'Manual',
        },
        actionMethod: {
            machine: 'Machine',
            manual: 'Machine+Manual',
        },
    },
};
