/**
 * Strategy Config Automated Config
 *
 * 机审配置
 */
import { useRequest, useSetState } from 'ahooks';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Drawer, Flex, Form, Select } from 'antd';
import { ProTable } from '@ant-design/pro-components';

/**
 * APIs
 */
import { reviewsConfigApi, reviewsConfigUpdateApi } from '@/services/reviews';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { PaginationProps } from 'antd';
import type { ReviewConfig } from '@/services/reviews';
import type { FormProps, DrawerProps } from 'antd';

/**
 * State
 */
type State = {
    dataSource: ReviewConfig[];
    entity: ReviewConfig | null;
    open: boolean;
};

const PageAutomatedConfig = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const [form] = Form.useForm();

    const { formatMessage } = useIntl();

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        entity: null,
        open: false,
    });
    // const intl = useIntl();

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    const {
        loading: loadingReviewsConfigApi,
        refresh: refreshReviewsConfigApi,
        run: runReviewsConfigApi,
    } = useRequest(reviewsConfigApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data,
            });

            setPagination({
                total: data.length,
            });
        },
    });

    const { loading: loadingReviewsConfigUpdateApi, run: runReviewsConfigUpdateApi } = useRequest(
        reviewsConfigUpdateApi,
        {
            manual: true,
            onSuccess: () => {
                setState({
                    open: false,
                });

                refreshReviewsConfigApi();
            },
        },
    );

    /**
     * Constants
     */

    const statusOptions = [
        {
            label: formatMessage({ id: 'cm.reviewsResult.approved' }),
            value: 'approved',
        },
        {
            label: formatMessage({ id: 'cm.reviewsResult.rejected' }),
            value: 'rejected',
        },
        {
            label: formatMessage({ id: 'cm.reviewsResult.manual' }),
            value: 'manual',
        },
    ];

    const statusEnum = {
        approved: <FormattedMessage id="cm.reviewsResult.approved" />,
        rejected: <FormattedMessage id="cm.reviewsResult.rejected" />,
        manual: <FormattedMessage id="cm.reviewsResult.manual" />,
    };

    /**
     * ChildrenProps
     */

    const formProps: FormProps = {
        form,
        layout: 'vertical',
        onFinish: (values) => {
            runReviewsConfigUpdateApi(values, state.entity?.id ?? '');
        },
    };

    const drawerProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setState({
                            open: false,
                        });
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button loading={loadingReviewsConfigUpdateApi} type="primary" onClick={() => form.submit()}>
                    <FormattedMessage id="b.confirm" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: state.open,
        title: <FormattedMessage id="b.edit" />,
        zIndex: 1100,
        afterOpenChange: (open) => open || form.resetFields(),
        onClose: () => {
            setState({
                entity: null,
                open: false,
            });
        },
    };

    const proTableProps: ProTableProps<ReviewConfig, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'type',
                ellipsis: true,
                title: <FormattedMessage id="cm.t.c.type" />,
                valueEnum: {
                    post: <FormattedMessage id="cm.type.post" />,
                    post_comment: <FormattedMessage id="cm.type.postComment" />,
                    message: <FormattedMessage id="cm.type.message" />,
                    shorts: <FormattedMessage id="cm.type.shorts" />,
                    shorts_comment: <FormattedMessage id="cm.type.shortsComment" />,
                    user: <FormattedMessage id="cm.type.user" />,
                },
                width: 200,
            },
            {
                dataIndex: 'approved',
                title: <FormattedMessage id="cm.t.c.normal" />,
                valueEnum: statusEnum,
                width: 200,
            },
            {
                dataIndex: 'suspected',
                title: <FormattedMessage id="cm.t.c.suspected" />,
                valueEnum: statusEnum,
                width: 200,
            },
            {
                dataIndex: 'rejected',
                title: <FormattedMessage id="cm.t.c.rejected" />,
                valueEnum: statusEnum,
                width: 200,
            },
            {
                dataIndex: 'failure',
                title: <FormattedMessage id="cm.t.c.failure" />,
                valueEnum: statusEnum,
                width: 200,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => (
                    <a
                        key="detail"
                        onClick={() => {
                            setState({
                                entity,
                                open: true,
                            });

                            form.setFieldsValue({
                                approved: entity.approved,
                                rejected: entity.rejected,
                                suspected: entity.suspected,
                                failure: entity.failure,
                            });
                        }}
                    >
                        <FormattedMessage id="b.edit" />
                    </a>
                ),
            },
        ],
        dataSource: state.dataSource,
        loading: loadingReviewsConfigApi,
        options: {
            reload: refreshReviewsConfigApi,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 1050,
            y: `calc(100vh - 280px - 8px)`,
        },
        search: false,
    };

    useEffect(() => {
        runReviewsConfigApi();
    }, []);

    return (
        <>
            <ProTable {...proTableProps} />

            <Drawer {...drawerProps}>
                <Form {...formProps}>
                    <Form.Item
                        label={<FormattedMessage id="cm.t.c.normal" />}
                        name="approved"
                        style={{ marginRight: 0 }}
                        rules={[{ required: true, message: formatMessage({ id: 'common.notEmpty' }) }]}
                    >
                        <Select
                            allowClear={true}
                            optionFilterProp="label"
                            options={statusOptions}
                            placeholder={<FormattedMessage id="cm.t.c.normal" />}
                            showSearch={true}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="cm.t.c.suspected" />}
                        name="suspected"
                        style={{ marginRight: 0 }}
                        rules={[{ required: true, message: formatMessage({ id: 'common.notEmpty' }) }]}
                    >
                        <Select
                            allowClear={true}
                            optionFilterProp="label"
                            options={statusOptions}
                            placeholder={<FormattedMessage id="cm.t.c.suspected" />}
                            showSearch={true}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="cm.t.c.rejected" />}
                        name="rejected"
                        style={{ marginRight: 0 }}
                        rules={[{ required: true, message: formatMessage({ id: 'common.notEmpty' }) }]}
                    >
                        <Select
                            allowClear={true}
                            optionFilterProp="label"
                            options={statusOptions}
                            placeholder={<FormattedMessage id="cm.t.c.rejected" />}
                            showSearch={true}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="cm.t.c.failure" />}
                        name="failure"
                        style={{ marginRight: 0 }}
                        rules={[{ required: true, message: formatMessage({ id: 'common.notEmpty' }) }]}
                    >
                        <Select
                            allowClear={true}
                            optionFilterProp="label"
                            options={statusOptions}
                            placeholder={<FormattedMessage id="cm.t.c.failure" />}
                            showSearch={true}
                        />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default PageAutomatedConfig;
