/**
 * Automated Moderation Posts
 *
 * 机审帖子
 */

import { useRequest, useSetState } from 'ahooks';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Select } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { get, isEmpty, omitBy } from 'lodash';

/**
 * Components
 */
import TableCellMedia from '@/components/table-cell-media';
import ComponentTableCellDebate from '@/components/table-cell-debate';
import ComponentTableCellAudio from '@/components/table-cell-audio';

/**
 * APIs
 */
import { humanRecordsPostApi } from '@/services/reviews';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { FormProps, PaginationProps } from 'antd';
import type { HumanRecordsRecordResult, HumanRecordsRequestsParams, PostContent } from '@/services/reviews';
import ComponentSearchFormSelectOperator from '@/components/search-form-select-operator';
import dayjs from 'dayjs';

/**
 * Constants
 */

/**
 * State
 */
type State = {
    dataSource: HumanRecordsRecordResult<PostContent>[];
};

const PageAutomatedModerationPosts: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });
    const intl = useIntl();

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<HumanRecordsRequestsParams>({
        type: 'post',
        created_start: dayjs().startOf('d').format(),
        created_end: dayjs().endOf('d').format(),
    });

    const {
        loading: loadingHumanRecordsApi,
        refresh: refreshHumanRecordsApi,
        run: runHumanRecordsApi,
    } = useRequest(humanRecordsPostApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });

    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        initialValues: {
            post_time: [dayjs().startOf('d'), dayjs().endOf('d')],
        },
        onFinish: (values) => {
            if (values?.post_time) {
                values.created_end = values.post_time[1].endOf('d').format();
                values.created_start = values.post_time[0].startOf('d').format();
                delete values.post_time;
            }

            if (values?.operate_time) {
                values.operated_end = values.operate_time[1].endOf('d').format();
                values.operated_start = values.operate_time[0].startOf('d').format();
                delete values.operate_time;
            }

            setPagination({
                current: 1,
            });

            setPayload({
                ...omitBy(values, isEmpty),
                action_result: values.action_result,
                violation_type: values.violation_type,
            });
        },
    };

    const proTableProps: ProTableProps<HumanRecordsRecordResult<PostContent>, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'biz_id',
                ellipsis: true,
                title: <FormattedMessage id="cm.t.c.postUid" />,
                width: 200,
            },
            {
                dataIndex: 'user_id',
                ellipsis: true,
                title: <FormattedMessage id="cm.t.c.userId" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'body'],
                title: <FormattedMessage id="cm.t.c.content" />,
                ellipsis: true,
            },
            {
                dataIndex: ['content', 'debate', 'title'],
                title: <FormattedMessage id="cm.t.c.debate" />,
                width: 200,
            },
            {
                dataIndex: '_debate_users',
                title: <FormattedMessage id="cm.t.c.debateUsers" />,
                render: (_, entity: any) => (
                    <ComponentTableCellDebate
                        forText={get(entity, 'content.debate.for_text', '')}
                        againstText={get(entity, 'content.debate.against_text', '')}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: '_media',
                title: <FormattedMessage id="cm.t.c.postImg" />,
                render: (_, entity: any) => <TableCellMedia list={get(entity, 'content.uploaded_materials', [])} />,
                width: 320,
            },
            {
                dataIndex: '_audio',
                title: <FormattedMessage id="cm.t.c.postAudio" />,
                width: 320,
                render: (_, entity: any) => {
                    const audio = get(entity, 'content.audio', null);
                    return <ComponentTableCellAudio srcs={audio ? [audio] : []} />;
                },
            },
            {
                dataIndex: 'requested_at',
                valueType: 'dateTime',
                title: <FormattedMessage id="cm.t.c.postTime" />,
                width: 200,
            },
            {
                dataIndex: 'action_result',
                title: <FormattedMessage id="cm.t.c.result" />,
                width: 200,
                valueEnum: {
                    approved: <FormattedMessage id="cm.status.approved" />,
                    suspected: <FormattedMessage id="cm.status.suspicious" />,
                    rejected: <FormattedMessage id="cm.status.rejected" />,
                    failure: <FormattedMessage id="cm.status.failure" />,
                    pending: <FormattedMessage id="cm.status.pending" />,
                },
            },
            {
                dataIndex: ['result_human', 0, 'violation_type'],
                title: <FormattedMessage id="cm.t.c.violationType" />,
                width: 200,
                valueEnum: {
                    'Politics&Religion': <FormattedMessage id="cm.violationType.politicsReligion" />,
                    Pornography: <FormattedMessage id="cm.violationType.pornography" />,
                    'Terrorism&Violence': <FormattedMessage id="cm.violationType.terrorismViolence" />,
                    'Violence&Gore': <FormattedMessage id="cm.violationType.violenceGore" />,
                    MinorProtection: <FormattedMessage id="cm.violationType.minorProtection" />,
                    ControversialSpeech: <FormattedMessage id="cm.violationType.controversialSpeech" />,
                    FalseFraud: <FormattedMessage id="cm.violationType.falseFraud" />,
                    'Illegal&ControversialContent': (
                        <FormattedMessage id="cm.violationType.illegalControversialContent" />
                    ),
                    PrivacyProtection: <FormattedMessage id="cm.violationType.privacyProtection" />,
                    'Traffic&Promotion': <FormattedMessage id="cm.violationType.trafficPromotion" />,
                },
            },
            {
                dataIndex: ['result_human', 0, 'reason'],
                title: <FormattedMessage id="t.c.remark" />,
                width: 200,
            },
            {
                dataIndex: ['result_human', 0, 'operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: ['result_human', 0, 'reviewed_at'],
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        loading: loadingHumanRecordsApi,
        options: {
            reload: refreshHumanRecordsApi,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 2900,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runHumanRecordsApi({
            ...payload,
            page: pagination.current as any,
            size: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="biz_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cm.t.c.postUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cm.t.c.userId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="content" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cm.t.c.content' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="debate" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cm.t.c.debate' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        {/* <Form.Item
                            label={<FormattedMessage id="t.c.type" />}
                            name="post_type"
                            style={{ marginRight: 0 }}
                        >
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cm.t.s.debate' }),
                                        value: 'debate',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.t.s.image' }),
                                        value: 'image',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.t.s.audio' }),
                                        value: 'audio',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.t.s.video' }),
                                        value: 'video',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.type" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item> */}

                        <Form.Item
                            label={<FormattedMessage id="cm.t.c.postTime" />}
                            name="post_time"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Form.Item
                            label={<FormattedMessage id="cm.t.c.result" />}
                            name="action_result"
                            style={{ marginRight: 0 }}
                        >
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cm.status.approved' }),
                                        value: 'approved',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.status.rejected' }),
                                        value: 'rejected',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="cm.t.c.statusMachine" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={<FormattedMessage id="cm.t.c.violationType" />}
                            name="violation_type"
                            style={{ marginRight: 0 }}
                        >
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.politicsReligion' }),
                                        value: 'Politics&Religion',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.pornography' }),
                                        value: 'Pornography',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.terrorismViolence' }),
                                        value: 'Terrorism&Violence',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.violenceGore' }),
                                        value: 'Violence&Gore',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.minorProtection' }),
                                        value: 'MinorProtection',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.controversialSpeech' }),
                                        value: 'ControversialSpeech',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.falseFraud' }),
                                        value: 'FalseFraud',
                                    },
                                    {
                                        label: intl.formatMessage({
                                            id: 'cm.violationType.illegalControversialContent',
                                        }),
                                        value: 'Illegal&ControversialContent',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.privacyProtection' }),
                                        value: 'PrivacyProtection',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.trafficPromotion' }),
                                        value: 'Traffic&Promotion',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="cm.t.c.violationType" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Content-Moderation_Moderation-Records_Post" />
                        </Form.Item>

                        <Form.Item
                            label={<FormattedMessage id="t.c.operatedAt" />}
                            name="operate_time"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingHumanRecordsApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageAutomatedModerationPosts;
