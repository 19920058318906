/**
 * Menu
 */
export default {
    m: {
        /**
         * Home
         *
         * 首页
         */
        Home: '首页',

        /**
         * Content Moderation
         *
         * 内容审核
         */
        'Content-Moderation': '内容审核',

        'Content-Moderation_Automated-Moderation': '机审记录',

        'Content-Moderation_Automated-Moderation_Post': '帖子',

        'Content-Moderation_Automated-Moderation_Shorts': '短视频',

        'Content-Moderation_Automated-Moderation_Memecoin': 'Memecoin',

        'Content-Moderation_Content-Moderation': '内容审核',

        'Content-Moderation_Content-Moderation_Post': '帖子',

        'Content-Moderation_Content-Moderation_Shorts': '短视频',

        'Content-Moderation_Content-Moderation_Memecoin': 'Memecoin',

        'Content-Moderation_Strategy-Config': '策略配置',

        'Content-Moderation_Strategy-Config_Automated-Config': '机审配置',

        'Content-Moderation_Moderation-Records': '审核记录',

        'Content-Moderation_Moderation-Records_Post': '帖子',

        'Content-Moderation_Moderation-Records_Shorts': '短视频',

        'Content-Moderation_Moderation-Records_Memecoin': 'Memecoin',

        /**
         * Reports
         *
         * 举报管理
         */
        reports: '举报管理',
        'report-moderation': '举报审核',
        'moderation-records': '审核记录',
        rm: {
            posts: '帖子',
            'posts-comments': '帖子评论',
            shorts: '短视频',
            'shorts-comments': '短视频评论',
            profiles: '个人资料',
        },

        /**
         * System Management
         *
         * 系统管理
         */
        'System-Management': '系统管理',

        'System-Management_Accounts': '账号管理',

        'System-Management_Roles': '角色管理',

        'System-Management_Logs': '系统日志',

        'System-Management_Exports': '下载管理',
    },
};
