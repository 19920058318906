import React from 'react';
import { Space, Image } from 'antd';

/**
 * Components
 */
import VideoPlayer from '@repo/ui/video-player';

/**
 * Constants
 */
const WEB2_ASSETS_URL = import.meta.env.VITE_WEB2_ASSETS || '';

/**
 * Props
 */
type UploadedMaterials = {
    type: 'video' | 'audio' | 'image' | 'shorts';
    url: string;
    cover?: string;
};

type Props = {
    list: (UploadedMaterials | string)[];
};

const ComponentTableCellMedia: React.FC<Props> = ({ list }) => {
    if (!list) {
        return '-';
    }
    const children: React.ReactNode[] = [];
    list.forEach((item, index: number) => {
        if (typeof item === 'string') {
            if (item.startsWith('video')) {
                children.push(<VideoPlayer src={`${WEB2_ASSETS_URL}/${item}`} key={index} />);
            } else if (item.startsWith('image')) {
                children.push(<Image src={`${WEB2_ASSETS_URL}/${item}`} width={80} key={index} />);
            }
            return;
        }
        if (item.type === 'video' || item.type === 'shorts') {
            children.push(<VideoPlayer src={item.url} key={index} cover={item.cover} />);
        } else if (item.type === 'image') {
            children.push(<Image src={item.url} width={80} key={index} />);
        }
    });
    if (!children.length) {
        return '-';
    }
    return <Space wrap={true}>{children}</Space>;
};

export default ComponentTableCellMedia;
