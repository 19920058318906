import { create } from 'zustand';

/**
 * Reviews Labels Store
 */
import type { ModerationLabels, ModerationLabelsType } from '@/services/reviews';

type ReviewLabelsStore = {
    labels: Partial<Record<ModerationLabelsType, ModerationLabels[]>> | null;
    setLabels: (type: ModerationLabelsType, labels: ModerationLabels[]) => void;
};

export const useReviewLabelsStore = create<ReviewLabelsStore>((set) => ({
    labels: null,
    setLabels: (type: ModerationLabelsType, labels: ModerationLabels[]) => {
        set((state) => ({
            labels: {
                ...(state.labels ?? {}),
                [type]: labels,
            },
        }));
    },
}));
