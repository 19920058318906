export default {
    'common.changePassword': '修改密码',
    signOut: '退出登录',

    'common.operatingPersonnel': '操作人',
    'common.operatingTime': '操作时间',
    'common.operation': '操作',
    'common.add': '添加',
    'common.edit': '编辑',
    'common.delete': '删除',
    'common.addAccount': '添加用户',
    'common.create': '创建',
    'common.account': '账号',
    'common.userName': '用户名',
    'common.status': '状态',
    'common.type': '类型',
    'common.role': '角色',
    'common.createPersonnel': '创建用户',
    'common.createTime': '创建时间',
    'common.password': '密码',
    'common.createAccount': '创建账号',
    'common.createRole': '创建角色',
    'common.updateRole': '编辑角色',
    'common.generated': '生成',
    'common.deleteOk': '删除成功',
    'common.submitSuccess': '提交成功',
    'common.sure': '确定',
    'common.cancel': '取消',
    'common.min': '最小值',
    'common.max': '最大值',
    'common.notEmpty': '不能为空',
    'common.positiveInteger': '正整数',
    'common.pleaseSelect': '请选择',
    'common.undefinedError': '未定义的系统错误',
    'common.dbQeeryError': 'DbQueryError 数据库请求错误',
    'common.nameRequired': '字段必填',
    'common.editUser': '编辑用户',
    'common.save': '保存',
    'common.editAccount': '编辑账号',
    'common.oldPassword': '旧密码',
    'common.newPassword': '新密码',
    'common.sureNewPassword': '确认新密码',
    'common.pleaseInputOldPassword': '请输入旧密码',
    'common.inputPasswordNotMatch': '新密码与确认密码不一致',
    'common.permissions': '权限管理',

    /**
     * 通用表格
     */
    'common.table.columns.sort': '排序',
    'common.table.columns.operatedBy': '操作人',
    'common.table.columns.operatedAt': '操作时间',
    'common.table.columns.action': '操作',
    notEmpty: '-',
};
