import axios from './index';

/**
 * 帖子
 */

// 帖子
export const PostsReportApi = (params: PostReportRequestsParams) => {
    return axios<PageResType<PostReportRecordResult>>('/v1/reports/posts', {
        method: 'get',
        params,
    });
};

// 更新帖子举报状态
export const changePostsReportStatusApi = (data: ChangeReportStatusParams) => {
    return axios<ResType<void>>('/v1/reports/posts', {
        method: 'put',
        data,
    });
};

// 帖子审核记录
export const postReportRecordApi = (params: PostReportRecordApiParams) => {
    return axios<PageResType<PostReportRecordResult>>('/v1/reports/records/posts', {
        method: 'get',
        params,
    });
};

/**
 * 帖子评论
 */

// 帖子评论
export const repliesReportApi = (params: RepliesReportRequestsParams) => {
    return axios<PageResType<RepliesReportRecordResult>>('/v1/reports/posts/comments', {
        method: 'get',
        params,
    });
};

// 更新帖子评论举报状态
export const changePostsCommentsReportStatusApi = (data: ChangeReportStatusParams) => {
    return axios<ResType<void>>('/v1/reports/posts/comments', {
        method: 'put',
        data,
    });
};

// 帖子评论审核记录
export const postCommentsReportRecordsApi = (params: PostCommentsReportRecordsApiParams) => {
    return axios<PageResType<RepliesReportRecordResult>>('/v1/reports/records/posts/comments', {
        method: 'get',
        params,
    });
};

/**
 * 用户资料
 */

// 用户资料
export const profileReportApi = (params: ProfileReportRequestsParams) => {
    return axios<PageResType<ProfileReportResult>>('/v1/reports/profile', {
        method: 'get',
        params,
    });
};

// 用户资料正常
export const changeUserNormalApi = (data: ChangeReportStatusParams) => {
    return axios<ResType<void>>(`/v1/reports/profile`, {
        method: 'put',
        data,
    });
};

// 用户资料重置
export const changeUserReportStatusApi = (id: string | number, items: string[]) => {
    return axios<ResType<void>>(`/v1/reports/profile/reset/${id}`, {
        method: 'put',
        data: {
            items,
        },
    });
};

// 用户资料审核记录
export const profileReportRecordsApi = (params: ProfileReportRecordsApi) => {
    return axios<PageResType<ProfileReportResult>>('/v1/reports/records/profile', {
        method: 'get',
        params,
    });
};

/**
 * 短视频
 */
// 短视频
export const shortsReportApi = (params: PostReportRequestsParams) => {
    return axios<PageResType<PostReportRecordResult>>('/v1/reports/videos', {
        method: 'get',
        params,
    });
};
// 更新短视频举报状态
export const changeShortsReportStatusApi = (data: ChangeReportStatusParams) => {
    return axios<ResType<void>>('/v1/reports/videos', {
        method: 'put',
        data,
    });
};

// 短视频审核记录
export const shortsReportReordsApi = (params: ShortsReportReordsApiParams) => {
    return axios<PageResType<PostReportRecordResult>>('/v1/reports/records/videos', {
        method: 'get',
        params,
    });
};

/**
 * 短视频评论
 */

// 短视频评论
export const shortsCommentsReportApi = (params: PostReportRequestsParams) => {
    return axios<PageResType<RepliesReportRecordResult>>('/v1/reports/videos/comments', {
        method: 'get',
        params,
    });
};

// 更新短视频评论举报状态
export const changeShortsCommentsReportStatusApi = (data: ChangeReportStatusParams) => {
    return axios<ResType<void>>('/v1/reports/videos/comments', {
        method: 'put',
        data,
    });
};

// 短视频评论审核记录
export const shortsCommentsReportRecordsApi = (params: ShortsCommentsReportRecordsApiParams) => {
    return axios<PageResType<RepliesReportRecordResult>>('/v1/reports/records/videos/comments', {
        method: 'get',
        params,
    });
};

/**
 * Types
 */
import type { PageResType, ResType } from '@repo/typings/common';

/**
 * 举报类型
 */
export enum ReportType {
    Misinformation = 'misinformation',
    Other = 'other',
    PlatformSafety = 'platform_safety',
    Racism = 'racism',
}

/**
 * 状态
 */
export enum Status {
    Normal = 'normal',
    Pending = 'pending',
    Violate = 'violate',
}

// 举报响应公共参数
export type ReportCommon = {
    /**
     * 创建时间
     */
    created_at: Date;
    /**
     * 删除时间
     */
    deleted_at?: Date | null;
    /**
     * ID
     */
    id: string;
    /**
     * 操作人
     */
    operated_by: string;
    /**
     * 其他原因
     */
    reason?: null | string;
    /**
     * 举报类型
     */
    report_type: ReportType;
    /**
     * 重置项，以英文逗号隔开
     */
    reset_items?: null | string;
    /**
     * 状态
     */
    status: Status;
    type: string;
    /**
     * 更新时间
     */
    updated_at: Date;
};

// 帖子举报
export type PostReportRecordResult = {
    content: PostReportContentResult;
} & ReportCommon;

// 帖子举报Api参数
export type PostReportRequestsParams = {
    /**
     * 操作结束时间
     */
    create_end?: string;
    /**
     * 操作开始时间
     */
    create_start?: string;
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operated_by?: string;
    order_by?: string;
    order_sort?: string;
    page?: number;
    /**
     * 帖子ID
     */
    post_id?: string;
    /**
     * 举报者ID
     */
    report_uid?: string;
    size?: number;
    /**
     * 状态
     */
    status?: Status[];
};

export type PostReportFormParams = PostReportRequestsParams & {
    operation_date: any;
};
// 评论举报
export type RepliesReportRecordResult = {
    content: RepliesReportContentResult;
} & ReportCommon;

// 评论举报
export type RepliesReportRequestsParams = {
    /**
     * 操作结束时间
     */
    create_end?: string;
    /**
     * 操作开始时间
     */
    create_start?: string;
    /**
     * 发布者ID
     */
    creator_uid?: string;
    /**
     * 评论ID
     */
    evaluate_id?: string;
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operator?: string;
    order_by?: string;
    order_sort?: string;
    page?: number;
    /**
     * 帖子ID
     */
    post_id?: string;
    /**
     * 举报者ID
     */
    report_uid?: string;
    size?: number;
    /**
     * 状态
     */
    status: Status[];
    operation_date?: string[];
};

// 用户举报
export type ProfileReportResult = {
    content: ProfileReportContentResult;
} & ReportCommon;

// 用户举报Api参数
export type ProfileReportRequestsParams = {
    /**
     * 操作结束时间
     */
    create_end?: string;
    /**
     * 操作开始时间
     */
    create_start?: string;
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operator?: string;
    order_by?: string;
    order_sort?: string;
    page?: number;
    /**
     * 举报者ID
     */
    report_uid?: string;
    size?: number;
    /**
     * 状态
     */
    status: Status[];
    /**
     * 用户ID
     */
    user_id?: string;
};

export type ChangeReportStatusParams = {
    /**
     * 举报ID
     */
    ids: string[];
    /**
     * 状态
     */
    status: Status;
};

// ==================  new
/**
 * 举报用户信息
 */
export type ReportUserInfo = {
    avatar: string;
    bio: string;
    nickname: string;
    user_id: string;
    username: string;
};

/**
 * 被举报帖子内容
 *
 * @desc 仅在 type 为 2、3 时存在
 */
export type ReportedPostContent = {
    audios: Audio[];
    content: Content;
    ext: Ext;
    post_id: string;
    post_type: string;
    status: string;
    userInfo: UserInfo;
};

/**
 * 帖子音频
 */
export type Audio = {
    audio_duration?: number;
    text?: string;
    url: string;
};

/**
 * 帖子内容
 */
export type Content = {
    body: string;
    debate: Debate;
    uploaded_materials: string[];
};

/**
 * 投票信息
 *
 * @desc 投票帖有内容，否则为 null
 */
export type Debate = {
    against_text: string;
    expired_at: number;
    for_text: string;
    title: string;
};

/**
 * 扩展信息
 */
export type Ext = {
    debate_ext: Debateext;
};

/**
 * 投票扩展信息
 */
export type Debateext = {
    vote_against_count: number;
    vote_for_count: number;
};

/**
 * 帖子用户信息
 */
export type UserInfo = {
    avatar: string;
    bio: string;
    nickname: string;
    user_id: string;
    username: string;
};

/**
 * 被举报用户信息
 */
export type ReportedUserInfo = {
    avatar: string;
    bio: string;
    nickname: string;
    user_id: string;
    username: string;
};

/**
 * 评论内容
 */
export type ReportedReplyContentContent = {
    audio_duration: number;
    body: string;
    uploaded_materials: string[];
};

/**
 * 评论用户信息
 */
export type ReportedReplyContentUserInfo = {
    avatar: string;
    bio: string;
    nickname: string;
    user_id: string;
    username: string;
};

/**
 * 被举报评论内容
 *
 * @desc 仅在 type 为 2 时存在
 */
export type ReportedReplyContent = {
    comment_id: string;
    content: ReportedReplyContentContent;
    parentInfo: null;
    status: string;
    userInfo: ReportedReplyContentUserInfo;
};

/**
 * 帖子举报
 */
export type PostReportContentResult = Omit<RepliesReportContentResult, 'reportedReplyContent'>;

/**
 * 评论举报
 */
export type RepliesReportContentResult = {
    reportedPostContent: ReportedPostContent;
    reportedReplyContent: ReportedReplyContent;
    reportedUserInfo: ReportedUserInfo;
    reportReason: string;
    reportType: string;
    reportUserInfo: ReportUserInfo;
    type: number;
};

/**
 * 用户举报
 */
export type ProfileReportContentResult = {
    reportedUserInfo: ReportedUserInfo;
    reportReason: string;
    reportType: string;
    reportUserInfo: ReportUserInfo;
    type: number;
};

/**
 * 帖子审核记录
 */
export type PostReportRecordApiParams = {
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operator?: string;
    order_by?: string;
    order_sort?: string;
    page?: string;
    /**
     * 帖子ID
     */
    post_id?: string;
    /**
     * 举报者ID
     */
    report_uid?: string;
    size?: string;
    /**
     * 状态
     */
    status?: string[];
    /**
     * 操作结束时间
     */
    updated_end?: string;
    /**
     * 操作开始时间
     */
    updated_start?: string;

    operation_date?: any[];
};

export type FePostReportRecordApiParams = PostReportRecordApiParams & {
    status?: string[] | [];
};

/**
 * 帖子评论审核记录
 */
export type PostCommentsReportRecordsApiParams = {
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operator?: string;
    order_by?: string;
    order_sort?: string;
    page?: string;
    /**
     * 评论 ID
     */
    post_comment_id?: string;
    /**
     * 所属帖子 ID
     */
    post_id?: string;
    /**
     * 举报者ID
     */
    report_uid?: string;
    /**
     * 发布者 ID
     */
    reportd_uid?: string;
    size?: string;
    /**
     * 状态
     */
    status?: string[];
    /**
     * 操作结束时间
     */
    updated_end?: string;
    /**
     * 操作开始时间
     */
    updated_start?: string;

    operation_date?: any[];
};

/**
 * 个人资料审核记录
 */
export type ProfileReportRecordsApi = {
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operator?: string;
    order_by?: string;
    order_sort?: string;
    page?: string;
    /**
     * 举报者ID
     */
    report_uid?: string;
    /**
     * 发布者 ID
     */
    reportd_uid?: string;
    size?: string;
    /**
     * 状态
     */
    status?: string[];
    /**
     * 操作结束时间
     */
    updated_end?: string;
    /**
     * 操作开始时间
     */
    updated_start?: string;
    operation_date?: any[];
};

/**
 * 短视频审核记录
 */

export type ShortsReportReordsApiParams = {
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operator?: string;
    order_by?: string;
    order_sort?: string;
    page?: string;
    /**
     * 举报者ID
     */
    report_uid?: string;
    size?: string;
    /**
     * 状态
     */
    status?: string[];
    /**
     * 操作结束时间
     */
    updated_end?: string;
    /**
     * 操作开始时间
     */
    updated_start?: string;
    /**
     * 短视频 ID
     */
    video_id?: string;

    operation_date?: any[];
};

/**
 * 短视频评论审核记录
 */

export type ShortsCommentsReportRecordsApiParams = {
    /**
     * 举报ID
     */
    id?: string;
    /**
     * 操作人
     */
    operator?: string;
    order_by?: string;
    order_sort?: string;
    page?: string;
    /**
     * 举报者ID
     */
    report_uid?: string;
    /**
     * 发布者 ID
     */
    reportd_uid?: string;
    size?: string;
    /**
     * 状态
     */
    status?: string[];
    /**
     * 操作结束时间
     */
    updated_end?: string;
    /**
     * 操作开始时间
     */
    updated_start?: string;
    /**
     * 评论 ID
     */
    video_comment_id?: string;
    /**
     * 所属短视频 ID
     */
    video_id?: string;

    operation_date?: any[];
};

export type UploadedMaterials = {
    type: 'video' | 'audio' | 'image';
    url: string;
};
