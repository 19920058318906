/**
 * Automated Moderation Memecoin
 *
 * 机审Memecoin
 */

import { useRequest, useSetState } from 'ahooks';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Select, Image, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { get, isEmpty, omitBy } from 'lodash';

/**
 * Components
 */
import ComponentSearchModerationLabel from '@/components/search-moderation-label';
import ComponentTableCellModerationLabel from '@/components/table-cell-moderation-label';
import SearchUser from '@/components/search-user';

/**
 * APIs
 */
import {
    gModerationLabels,
    ModerationLabelsType,
    ModerationLabelsTypeText,
    memecoinReviewApi,
    PostReviewsMachineEnum,
    PostReviewsSuggestionEnum,
} from '@/services/reviews';

/**
 * Stores
 */
import { useReviewLabelsStore } from '@/store/review-labels';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { FormProps, PaginationProps } from 'antd';
import type { MemecoinReviewRecordResult, MemecoinReviewRequestsParams } from '@/services/reviews';

/**
 * Constants
 */
// const WEB2_ASSETS_URL = import.meta.env.VITE_WEB2_ASSETS || '';

/**
 * State
 */
type State = {
    dataSource: MemecoinReviewRecordResult[];
};

const PageAutomatedModerationMemecoin: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });
    const intl = useIntl();

    const { setLabels } = useReviewLabelsStore();

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<MemecoinReviewRequestsParams>({});

    const {
        loading: loadingMemecoinReviewApi,
        refresh: refreshMemecoinReviewApi,
        run: runMemecoinReviewApi,
    } = useRequest(memecoinReviewApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });

    /**
     * Requests
     */
    useRequest(() => gModerationLabels({ type: ModerationLabelsType.text, language: 'zh' }), {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setLabels(ModerationLabelsType.text, data);
        },
    });

    useRequest(() => gModerationLabels({ type: ModerationLabelsType.image, language: 'zh' }), {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setLabels(ModerationLabelsType.image, data);
        },
    });

    useRequest(() => gModerationLabels({ type: ModerationLabelsType.audio, language: 'zh' }), {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setLabels(ModerationLabelsType.audio, data);
        },
    });

    useRequest(() => gModerationLabels({ type: ModerationLabelsType.video, language: 'zh' }), {
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setLabels(ModerationLabelsType.video, data);
        },
    });

    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values?.post_time) {
                values.created_end = values.post_time[1].endOf('d').format();
                values.created_start = values.post_time[0].startOf('d').format();
                delete values.post_time;
            }

            if (Array.isArray(values.text_label)) {
                values.text_label = values.text_label.map((item: string[]) => item.join(','));
            }

            if (Array.isArray(values.image_label)) {
                values.image_label = values.image_label.map((item: string[]) => item.join(','));
            }

            if (Array.isArray(values.audio_label)) {
                values.audio_label = values.audio_label.map((item: string[]) => item.join(','));
            }

            if (Array.isArray(values.video_label)) {
                values.video_label = values.video_label.map((item: string[]) => item.join(','));
            }

            setPagination({
                current: 1,
            });

            setPayload({
                ...omitBy(values, isEmpty),
                text_label: values.text_label,
                image_label: values.image_label,
                status_machine: values.status_machine,
                action_result: values.action_result,
            });
        },
    };

    const proTableProps: ProTableProps<MemecoinReviewRecordResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'biz_id',
                ellipsis: true,
                title: 'Meme ID',
                width: 200,
            },
            {
                dataIndex: ['content', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="cm.t.c.name" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'symbol'],
                ellipsis: true,
                title: <FormattedMessage id="cm.t.c.ticker" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'description'],
                title: <FormattedMessage id="cm.t.c.description" />,
                ellipsis: true,
            },
            {
                dataIndex: ['content', 'image_url'],
                title: <FormattedMessage id="cm.t.c.image" />,
                render: (_, entity: any) => <Image width="100%" src={entity.content.image_url} />,
                width: 200,
            },
            {
                dataIndex: ['content', 'socrates_group'],
                title: <FormattedMessage id="cm.t.c.group" />,
                ellipsis: true,
                width: 200,
                render: (_, entity: any) => (
                    <a href={entity.content.group} target="_blank">
                        {entity.content.socrates_group}
                    </a>
                ),
            },
            {
                dataIndex: ['content', 'x'],
                title: <FormattedMessage id="cm.t.c.x" />,
                ellipsis: true,
                width: 200,
                render: (_, entity: any) => (
                    <a href={entity.content.x} target="_blank">
                        {entity.content.x}
                    </a>
                ),
            },
            {
                dataIndex: ['content', 'website'],
                title: <FormattedMessage id="cm.t.c.website" />,
                ellipsis: true,
                width: 200,
                render: (_, entity: any) => (
                    <a href={entity.content.website} target="_blank">
                        {entity.content.website}
                    </a>
                ),
            },
            {
                dataIndex: ['content', 'telegram'],
                title: <FormattedMessage id="cm.t.c.telegram" />,
                ellipsis: true,
                width: 200,
                render: (_, entity: any) => (
                    <a href={entity.content.telegram} target="_blank">
                        {entity.content.telegram}
                    </a>
                ),
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.createdBy" />,
                width: 200,
                render: (_, entity) => {
                    const { username = '-', nickname = '-', id = '' } = entity.user_info || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: id, tooltips: 'Copy UID' }}>@{username}</Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: '_text_labels',
                title: <FormattedMessage id="cm.t.c.textTag" />,
                width: 200,
                render: (_, entity: any) => {
                    const result_machine = get(entity, 'result_machine', []);
                    const item_machine = result_machine?.filter(
                        (item: any) => item.type === ModerationLabelsTypeText.text,
                    );
                    const showLabels: React.ReactNode[] = [];

                    item_machine?.forEach((item: any) => {
                        const antispam = get(item, 'response.result.antispam', {});
                        const status = get(item, 'response.status', PostReviewsMachineEnum.approved);
                        if (antispam?.suggestion !== PostReviewsSuggestionEnum.normal) {
                            showLabels.push(
                                <ComponentTableCellModerationLabel
                                    antispam={antispam}
                                    status={status}
                                    type={ModerationLabelsType.text}
                                    id={entity.id}
                                />,
                            );
                        }
                    });
                    return <>{Array.isArray(showLabels) && showLabels.length > 0 ? showLabels : '-'}</>;
                },
            },
            {
                dataIndex: '_img_labels',
                title: <FormattedMessage id="cm.t.c.imgTag" />,
                width: 200,
                render: (_, entity: any) => {
                    const result_machine = get(entity, 'result_machine', []);
                    const item_machine = result_machine.filter(
                        (item: any) => item.type === ModerationLabelsTypeText.image,
                    );

                    const showLabels: React.ReactNode[] = [];
                    item_machine.forEach((item: any) => {
                        const antispam = get(item, 'response.result.antispam', {});
                        const status = get(item, 'response.status', PostReviewsMachineEnum.approved);
                        if (antispam?.suggestion !== PostReviewsSuggestionEnum.normal) {
                            showLabels.push(
                                <ComponentTableCellModerationLabel
                                    antispam={antispam}
                                    status={status}
                                    type={ModerationLabelsType.image}
                                    id={entity.id}
                                />,
                            );
                        }
                    });
                    return <>{Array.isArray(showLabels) && showLabels.length > 0 ? showLabels : '-'}</>;
                },
            },
            {
                dataIndex: 'created_at',
                valueType: 'dateTime',
                title: <FormattedMessage id="cm.t.c.createdAt" />,
                width: 200,
            },
            {
                dataIndex: 'status_machine',
                title: <FormattedMessage id="cm.t.c.statusMachine" />,
                width: 200,
                valueEnum: {
                    approved: <FormattedMessage id="cm.status.approved" />,
                    suspected: <FormattedMessage id="cm.status.suspected" />,
                    rejected: <FormattedMessage id="cm.status.rejected" />,
                    failure: <FormattedMessage id="cm.status.failure" />,
                    pending: <FormattedMessage id="cm.status.pending" />,
                },
            },
            {
                dataIndex: 'action_method',
                title: <FormattedMessage id="cm.t.c.actionMethod" />,
                width: 200,
                valueEnum: {
                    machine: <FormattedMessage id="cm.actionMethod.machine" />,
                    manual: <FormattedMessage id="cm.actionMethod.manual" />,
                },
            },
            {
                dataIndex: 'action_result',
                title: <FormattedMessage id="cm.t.c.statusExecution" />,
                width: 200,
                render: (_, entity) => {
                    const actionResult: string = get(entity, 'action_result', '-');
                    const actionMethod: string = get(entity, 'action_method', 'machine');
                    if (actionMethod === 'manual') {
                        return <>-</>;
                    }
                    if (actionResult === 'approved') {
                        return <FormattedMessage id="cm.executionResult.approved" />;
                    }
                    if (actionResult === 'rejected') {
                        return <FormattedMessage id="cm.executionResult.rejected" />;
                    }
                    return <>{actionResult || '-'}</>;
                },
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.approvedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        loading: loadingMemecoinReviewApi,
        options: {
            reload: refreshMemecoinReviewApi,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 3450,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runMemecoinReviewApi({
            ...payload,
            page: pagination.current as any,
            size: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="biz_id" style={{ marginRight: 0 }}>
                            <Input allowClear={true} placeholder="Meme ID" style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item name="name" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cm.t.c.name' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="symbol" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cm.t.c.ticker' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <SearchUser form={formSearch} />

                        <Form.Item name="text_label" style={{ marginRight: 0 }}>
                            <ComponentSearchModerationLabel
                                placeholder={<FormattedMessage id="cm.t.s.text" />}
                                type={ModerationLabelsType.text}
                            />
                        </Form.Item>

                        <Form.Item name="image_label" style={{ marginRight: 0 }}>
                            <ComponentSearchModerationLabel
                                placeholder={<FormattedMessage id="cm.t.s.image" />}
                                type={ModerationLabelsType.image}
                            />
                        </Form.Item>

                        <Form.Item
                            label={<FormattedMessage id="cm.t.c.createdAt" />}
                            name="post_time"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Form.Item name="status_machine" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cm.status.approved' }),
                                        value: 'approved',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.status.suspected' }),
                                        value: 'suspected',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.status.rejected' }),
                                        value: 'rejected',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.status.failure' }),
                                        value: 'failure',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.status.pending' }),
                                        value: 'pending',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="cm.t.c.statusMachine" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={<FormattedMessage id="cm.t.c.actionMethod" />}
                            name="action_method"
                            style={{ marginRight: 0 }}
                        >
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cm.actionMethod.machine' }),
                                        value: 'machine',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.actionMethod.manual' }),
                                        value: 'manual',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="cm.t.c.actionMethod" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="action_result" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cm.executionResult.approved' }),
                                        value: 'approved',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.executionResult.rejected' }),
                                        value: 'rejected',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="cm.t.c.statusExecution" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Button
                            loading={loadingMemecoinReviewApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageAutomatedModerationMemecoin;
