export default {
    'common.changePassword': 'Change password',
    signOut: 'Sign out',
    'common.operatingPersonnel': 'Operated by',
    'common.operatingTime': 'Operated at',
    'common.operation': 'Action',
    'common.add': 'Add',
    'common.edit': 'Edit',
    'common.delete': 'Delete',
    'common.addAccount': 'Add user',
    'common.create': 'Create',
    'common.account': 'Account',
    'common.userName': 'Username',
    'common.status': 'Status',
    'common.type': 'Type',
    'common.role': 'Role',
    'common.createPersonnel': 'Created by',
    'common.createTime': 'Created at',
    'common.password': 'Password',
    'common.createAccount': 'Create account',
    'common.createRole': 'Create role',
    'common.updateRole': 'Edit role',
    'common.generated': 'Generate',
    'common.deleteOk': 'Deleted successfully',
    'common.submitSuccess': 'Submitted successfully',
    'common.sure': 'Confirm',
    'common.cancel': 'Cancel',
    'common.min': 'Min.',
    'common.max': 'Max.',
    'common.notEmpty': 'Required',
    'common.positiveInteger': 'Integer',
    'common.pleaseSelect': '请选择',
    'common.undefinedError': 'Unknown system error',
    'common.dbQeeryError': 'Database request error',
    'common.nameRequired': 'Required',
    'common.editUser': 'Edit user',
    'common.save': 'Save',
    'common.editAccount': 'Edit account',
    'common.oldPassword': 'Current password',
    'common.newPassword': 'New password',
    'common.sureNewPassword': 'Confirm password',
    'common.pleaseInputOldPassword': 'Please input current password',
    'common.inputPasswordNotMatch': '新密码与确认密码不一致',
    'common.permissions': 'Permissions',

    /**
     * 通用表格
     */
    'common.table.columns.sort': 'Sort',
    'common.table.columns.operatedBy': 'Operated by',
    'common.table.columns.operatedAt': 'Operated at',
    'common.table.columns.action': 'Action',
    notEmpty: '-',
};
