import { flattenObject } from '@/utils';

/**
 * Resources
 */
// Common
import common from './common';

// Menu
import menu from './menu';

// Permission
import permission from './permission';

import systemManagementAccounts from './pages/system-management_accounts';
import systemManagementLogs from './pages/system-management_logs';
import systemManagementExports from './pages/system-management_exports';
import roleManagement from './pages/role-management';
import contentModeration from './pages/content-moderation';

import oldCommon from './modules/common';
import oldMenu from './modules/menu';

// Page Report-management
import report from './pages/report';

export default {
    // Common
    ...flattenObject(common),

    // Menu
    ...flattenObject(menu),

    // Permission
    ...flattenObject(permission),

    // Page
    ...flattenObject(systemManagementLogs),
    ...flattenObject(systemManagementExports),

    // Page - System-Management Accounts
    ...flattenObject(systemManagementAccounts),
    // Page - Report Management
    ...flattenObject(report),

    // Page - System-Management Role
    ...flattenObject(roleManagement),

    // Page - Content Moderation
    ...flattenObject(contentModeration),

    /**
     * Old translations
     */
    ...oldCommon,

    // Menu
    ...oldMenu,
};
