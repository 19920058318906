import { ProCard, ProTable } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, DatePicker, Flex, Form, Select } from 'antd';
import dayjs from 'dayjs';
import { mapValues } from 'lodash';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * APIs
 */
import { logs } from '@/services/logs';
import { operators } from '@/services/operator';

/**
 * Types
 */
import type { ProTableProps } from '@ant-design/pro-components';
import type { FormProps, PaginationProps } from 'antd';
import type { LogsParams, LogsResponse } from '@/services/logs';
import type { OperatorsResponse } from '@/services/operator';

type State = {
    dataSource: LogsResponse[];
    operators: OperatorsResponse[];
};

/**
 * Constants
 */
const defaultPayload: LogsParams = {
    category: undefined,
    module: undefined,
    operation_end: dayjs().endOf('d').format(),
    operation_start: dayjs().startOf('d').format(),
    operations: undefined,
    operator_account_id: undefined,
    status: 'Success',
};

const operationMaps = [
    {
        label: 'Add',
        value: 'Add',
    },
    {
        label: 'Delete',
        value: 'Delete',
    },
    {
        label: 'Edit',
        value: 'Edit',
    },
    {
        label: 'Import',
        value: 'Import',
    },
    {
        label: 'Export',
        value: 'Export',
    },
    {
        label: 'Login',
        value: 'Login',
    },
    {
        label: 'Logout',
        value: 'Logout',
    },
    {
        label: 'Change Password',
        value: 'Change Password',
    },
    {
        label: 'Other',
        value: 'Other',
    },
];

const PageLogs: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        operators: [],
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<LogsParams>({
        ...defaultPayload,
    });

    /**
     * Requests
     */
    useRequest(operators, {
        defaultParams: [{ limit: 9999, model: '4', page: 1 }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                operators: data.list,
            });
        },
    });

    const {
        loading: loadingLogs,
        refresh: refreshLogs,
        run: runLogs,
    } = useRequest(logs, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        initialValues: {
            status: 'Success',
            operation_date: [dayjs().startOf('d'), dayjs().endOf('d')],
        },
        layout: 'inline',
        onFinish: (values) => {
            if (values.operation_date) {
                values.operation_end = values.operation_date[1].endOf('d').format();
                values.operation_start = values.operation_date[0].startOf('d').format();
                values.operation_date = undefined;
            }

            setPagination({
                current: 1,
                pageSize: 20,
            });

            const assignPayload = {
                ...defaultPayload,
                ...mapValues(values, (value) => (value === '' ? undefined : value)),
            };

            setPayload(assignPayload);
        },
    };

    const proTableProps: ProTableProps<LogsResponse, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'menu',
                ellipsis: true,
                title: <FormattedMessage id="p.logs.t.c.menu" />,
                width: 200,
                renderText: (text, record) =>
                    ['Login', 'Logout', 'Change Password'].includes(record.menu) ? '-' : text,
            },
            {
                dataIndex: 'remote_address',
                title: 'IP',
                width: 120,
            },
            {
                dataIndex: 'operation',
                title: <FormattedMessage id="t.c.type" />,
                width: 120,
            },
            {
                copyable: true,
                dataIndex: 'params',
                ellipsis: true,
                title: <FormattedMessage id="p.logs.t.c.params" />,
                renderText: (text) => text && JSON.stringify(text),
            },
            {
                copyable: true,
                dataIndex: 'body',
                ellipsis: true,
                title: <FormattedMessage id="p.logs.t.c.body" />,
                renderText: (text) => text && JSON.stringify(text),
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="p.logs.t.c.result" />,
                width: 120,
            },
            {
                dataIndex: ['operator_account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        loading: loadingLogs,
        options: {
            reload: refreshLogs,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 1530,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runLogs({
            ...payload,
            limit: pagination.pageSize,
            page: pagination.current,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="operations" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                maxTagCount="responsive"
                                mode="multiple"
                                optionFilterProp="label"
                                options={operationMaps}
                                placeholder={<FormattedMessage id="t.c.type" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: 'Success',
                                        value: 'Success',
                                    },
                                    {
                                        label: 'Failure',
                                        value: 'Failure',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="p.logs.t.c.result" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={state.operators.map((operator) => ({
                                    label: operator.account.name,
                                    value: operator.account.id,
                                }))}
                                placeholder={<FormattedMessage id="t.c.operatedBy" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingLogs}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageLogs;
