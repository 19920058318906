/**
 * Automated Moderation Posts
 *
 * 机审帖子
 */

import { useRequest, useSetState } from 'ahooks';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Select, Image, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { isEmpty, omitBy } from 'lodash';
import dayjs from 'dayjs';

/**
 * Components
 */
import ComponentSearchFormSelectOperator from '@/components/search-form-select-operator';
import SearchUser from '@/components/search-user';

/**
 * APIs
 */
import { humanRecordsMemeApi } from '@/services/reviews';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { FormProps, PaginationProps } from 'antd';
import type { MemecoinReviewRecordResult, MemecoinReviewRequestsParams } from '@/services/reviews';

/**
 * Constants
 */

/**
 * State
 */
type State = {
    dataSource: MemecoinReviewRecordResult[];
};

const PageAutomatedModerationPosts: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });
    const intl = useIntl();

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<MemecoinReviewRequestsParams>({
        created_start: dayjs().startOf('d').format(),
        created_end: dayjs().endOf('d').format(),
    });

    const {
        loading: loadingMemecoinReviewApi,
        refresh: refreshMemecoinReviewApi,
        run: runMemecoinReviewApi,
    } = useRequest(humanRecordsMemeApi, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });

    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        initialValues: {
            created_at: [dayjs().startOf('d'), dayjs().endOf('d')],
        },
        onFinish: (values) => {
            if (values?.created_at) {
                values.created_end = values.created_at[1].endOf('d').format();
                values.created_start = values.created_at[0].startOf('d').format();
                delete values.created_at;
            }

            if (values?.operate_time) {
                values.operated_end = values.operate_time[1].endOf('d').format();
                values.operated_start = values.operate_time[0].startOf('d').format();
                delete values.operate_time;
            }

            setPagination({
                current: 1,
            });

            setPayload({
                ...omitBy(values, isEmpty),
                action_result: values.action_result,
                // violation_type: values.violation_type,
            });
        },
    };

    const proTableProps: ProTableProps<MemecoinReviewRecordResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'biz_id',
                ellipsis: true,
                title: 'Meme ID',
                width: 200,
            },
            {
                dataIndex: ['content', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="cm.t.c.name" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'symbol'],
                ellipsis: true,
                title: <FormattedMessage id="cm.t.c.ticker" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'description'],
                title: <FormattedMessage id="cm.t.c.description" />,
                ellipsis: true,
            },
            {
                dataIndex: ['content', 'image_url'],
                title: <FormattedMessage id="cm.t.c.image" />,
                render: (_, entity: any) => <Image width="100%" src={entity.content.image_url} />,
                width: 200,
            },
            {
                dataIndex: ['content', 'socrates_group'],
                title: <FormattedMessage id="cm.t.c.group" />,
                ellipsis: true,
                width: 200,
                render: (_, entity: any) => (
                    <a href={entity.content.group} target="_blank">
                        {entity.content.socrates_group}
                    </a>
                ),
            },
            {
                dataIndex: ['content', 'x'],
                title: <FormattedMessage id="cm.t.c.x" />,
                ellipsis: true,
                width: 200,
                render: (_, entity: any) => (
                    <a href={entity.content.x} target="_blank">
                        {entity.content.x}
                    </a>
                ),
            },
            {
                dataIndex: ['content', 'website'],
                title: <FormattedMessage id="cm.t.c.website" />,
                ellipsis: true,
                width: 200,
                render: (_, entity: any) => (
                    <a href={entity.content.website} target="_blank">
                        {entity.content.website}
                    </a>
                ),
            },
            {
                dataIndex: ['content', 'telegram'],
                title: <FormattedMessage id="cm.t.c.telegram" />,
                ellipsis: true,
                width: 200,
                render: (_, entity: any) => (
                    <a href={entity.content.telegram} target="_blank">
                        {entity.content.telegram}
                    </a>
                ),
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.createdBy" />,
                width: 200,
                render: (_, entity) => {
                    const { username = '-', nickname = '-', id = '' } = entity.user_info || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: id || '', tooltips: 'Copy UID' }}>
                                @{username}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: 'requested_at',
                valueType: 'dateTime',
                title: <FormattedMessage id="t.c.createdAt" />,
                width: 200,
            },
            {
                dataIndex: 'action_result',
                title: <FormattedMessage id="cm.t.c.result" />,
                width: 200,
                valueEnum: {
                    approved: <FormattedMessage id="cm.status.approved" />,
                    suspected: <FormattedMessage id="cm.status.suspicious" />,
                    rejected: <FormattedMessage id="cm.status.rejected" />,
                    failure: <FormattedMessage id="cm.status.failure" />,
                    pending: <FormattedMessage id="cm.status.pending" />,
                },
            },
            {
                dataIndex: ['result_human', 0, 'violation_type'],
                title: <FormattedMessage id="cm.t.c.violationType" />,
                width: 200,
                valueEnum: {
                    'Politics&Religion': <FormattedMessage id="cm.violationType.politicsReligion" />,
                    Pornography: <FormattedMessage id="cm.violationType.pornography" />,
                    'Terrorism&Violence': <FormattedMessage id="cm.violationType.terrorismViolence" />,
                    'Violence&Gore': <FormattedMessage id="cm.violationType.violenceGore" />,
                    MinorProtection: <FormattedMessage id="cm.violationType.minorProtection" />,
                    ControversialSpeech: <FormattedMessage id="cm.violationType.controversialSpeech" />,
                    FalseFraud: <FormattedMessage id="cm.violationType.falseFraud" />,
                    'Illegal&ControversialContent': (
                        <FormattedMessage id="cm.violationType.illegalControversialContent" />
                    ),
                    PrivacyProtection: <FormattedMessage id="cm.violationType.privacyProtection" />,
                    'Traffic&Promotion': <FormattedMessage id="cm.violationType.trafficPromotion" />,
                },
            },
            {
                dataIndex: ['result_human', 0, 'reason'],
                title: <FormattedMessage id="cm.t.c.reason" />,
                width: 200,
            },
            {
                dataIndex: ['result_human', 0, 'operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: ['result_human', 0, 'reviewed_at'],
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        loading: loadingMemecoinReviewApi,
        options: {
            reload: refreshMemecoinReviewApi,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 3250,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runMemecoinReviewApi({
            ...payload,
            page: pagination.current as any,
            size: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="biz_id" style={{ marginRight: 0 }}>
                            <Input allowClear={true} placeholder="Meme ID" style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item name="name" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cm.t.c.name' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="symbol" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cm.t.c.ticker' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <SearchUser form={formSearch} />

                        <Form.Item
                            label={<FormattedMessage id="t.c.createdAt" />}
                            name="created_at"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Form.Item name="action_result" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cm.status.approved' }),
                                        value: 'approved',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.status.rejected' }),
                                        value: 'rejected',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="cm.t.c.statusMachine" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="violation_type" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.politicsReligion' }),
                                        value: 'Politics&Religion',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.pornography' }),
                                        value: 'Pornography',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.terrorismViolence' }),
                                        value: 'Terrorism&Violence',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.violenceGore' }),
                                        value: 'Violence&Gore',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.minorProtection' }),
                                        value: 'MinorProtection',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.controversialSpeech' }),
                                        value: 'ControversialSpeech',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.falseFraud' }),
                                        value: 'FalseFraud',
                                    },
                                    {
                                        label: intl.formatMessage({
                                            id: 'cm.violationType.illegalControversialContent',
                                        }),
                                        value: 'Illegal&ControversialContent',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.privacyProtection' }),
                                        value: 'PrivacyProtection',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cm.violationType.trafficPromotion' }),
                                        value: 'Traffic&Promotion',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="cm.t.c.violationType" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Content-Moderation_Moderation-Records_Memecoin" />
                        </Form.Item>

                        <Form.Item
                            label={<FormattedMessage id="t.c.operatedAt" />}
                            name="operate_time"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingMemecoinReviewApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageAutomatedModerationPosts;
