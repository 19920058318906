/**
 * Permission
 */
export default {
    pm: {
        'Content-Moderation': 'Content Moderation',
        'Content-Moderation_Content-Moderation': 'Content Moderation',
        'Content-Moderation_Content-Moderation_Post': 'Posts',
        'Content-Moderation_Content-Moderation_Memecoin': 'Memecoin',
        'Content-Moderation_Moderation-Records': 'Moderation Records',
        'Content-Moderation_Moderation-Records_Post': 'Posts',
        'Content-Moderation_Moderation-Records_Memecoin': 'Memecoin',
        'Content-Moderation_Automated-Moderation': 'Automated Moderation',
        'Content-Moderation_Automated-Moderation_Post': 'Posts',
        'Content-Moderation_Automated-Moderation_Shorts': 'Shorts',
        'Content-Moderation_Automated-Moderation_Memecoin': 'Memecoin',
        'Content-Moderation_Strategy-Config': 'Strategy Config',
        'Content-Moderation_Strategy-Config_Automated-Config': 'Automated Config',
        Reports: 'Reports',
        'Reports_Report-Moderation': 'Report Moderation',
        'Reports_Report-Moderation_Posts': 'Posts',
        'Reports_Report-Moderation_Posts-Comments': 'Posts Comments',
        'Reports_Report-Moderation_Shorts': 'Shorts',
        'Reports_Report-Moderation_Shorts-Comments': 'Shorts Comments',
        'Reports_Report-Moderation_Profiles': 'Profiles',
        'Reports_Moderation-Records': 'Moderation Records',
        'Reports_Moderation-Records_Posts': 'Posts',
        'Reports_Moderation-Records_Posts-Comments': 'Posts Comments',
        'Reports_Moderation-Records_Shorts': 'Shorts',
        'Reports_Moderation-Records-Comments': 'Shorts Comments',
        'Reports_Moderation-Records_Profiles': 'Profiles',
        'System-Management': 'System Management',
        'System-Management_Accounts': 'Accounts',
        'System-Management_Accounts_Create': 'Create',
        'System-Management_Accounts_Edit': 'Edit',
        'System-Management_Accounts_Delete': 'Delete',
        'System-Management_Roles': 'Roles',
        'System-Management_Roles_Create': 'Create',
        'System-Management_Roles_Edit': 'Edit',
        'System-Management_Roles_Delete': 'Delete',
        'System-Management_Permissions': 'Permissions',
        'System-Management_Logs': 'Logs',
        'System-Management_Exports': 'Downloads',
        'System-Management_Exports_Exports': 'Downloads',
        'System-Management_Exports_Exports_All-Data': 'All Data',
        'System-Management_Exports_Exports_Personal-Data': 'Personal Data',
    },
};
