import React from 'react';
import { Space, Image } from 'antd';

/**
 * Components
 */
import VideoPlayer from '@repo/ui/video-player';

/**
 * Types
 */
// import type { ImageProps } from 'antd';

/**
 * Constants
 */
// const DEFAULT_COVER = '/video.png';
// const WEB2_ASSETS_URL = import.meta.env.VITE_WEB2_ASSETS || '';

/**
 * Props
 */
type UploadedMaterials = {
    type: 'video' | 'audio' | 'image';
    url: string;
    cover?: string;
};
type Props = {
    list: UploadedMaterials[];
};

const ComponentTableCellMedia: React.FC<Props> = ({ list }) => {
    if (!list) {
        return '-';
    }
    const children: React.ReactNode[] = [];
    list.forEach((item, index: number) => {
        if (item.type === 'video') {
            children.push(<VideoPlayer src={item.url} key={index} cover={item.cover} />);
        } else if (item.type === 'image') {
            children.push(<Image src={item.url} width={80} key={index} />);
        }
    });
    if (!children.length) {
        return '-';
    }
    return <Space wrap={true}>{children}</Space>;
};

export default ComponentTableCellMedia;
