import { PageResType, ResType } from '@repo/typings/common';
import axios from './index';

/**
 * APIs
 */

// 获取帖子审核分页列表
export const postReviewsApi = (params: PostReviewsRequestsParams) => {
    return axios<PageResType<PostReviewsRecordResult>>('/v1/reviews/post', {
        method: 'get',
        params,
    });
};

// 获取视频审核分页列表
export const videoReviewApi = (params: PostReviewsRequestsParams) => {
    return axios<PageResType<PostReviewsRecordResult>>('/v1/reviews/video', {
        method: 'get',
        params,
    });
};

// 获取meme上架机审列表
export const memecoinReviewApi = (params: MemecoinReviewRequestsParams) => {
    return axios<PageResType<MemecoinReviewRecordResult>>('/v1/reviews/meme', {
        method: 'get',
        params,
    });
};

/**
 * /v1/reviews/config
 */
export const reviewsConfigApi = () => {
    return axios<ResType<ReviewConfig[]>>('/v1/reviews/config', {
        method: 'get',
    });
};

// 更新机审配置
export const reviewsConfigUpdateApi = (params: PostReviewsConfigUpdateParams, id: string) => {
    return axios<ResType<ReviewConfig>>(`/v1/reviews/config/${id}`, {
        method: 'put',
        data: params,
    });
};

// 获取网易检测标签列表
export const gModerationLabels = (params: ModerationLabelsRequestsParams) => {
    return axios<ResType<ModerationLabels[]>>('/v1/reviews/netease/openapi/labels', {
        params,
    });
};

// 人工审核记录列表
export const humanRecordsPostApi = (params: HumanRecordsRequestsParams) => {
    return axios<PageResType<HumanRecordsRecordResult<PostContent>>>('/v1/reviews/human/records/post', {
        params,
    });
};

// 人工审核meme列表
export const humanRecordsMemeApi = (params: MemecoinReviewRequestsParams) => {
    return axios<PageResType<MemecoinReviewRecordResult>>('/v1/reviews/human/records/meme', {
        params,
    });
};

// 人工审核短视频列表
export const humanRecordsShortsApi = (params: HumanRecordsRequestsParams) => {
    return axios<PageResType<HumanRecordsRecordResult<ShortsContent>>>('/v1/reviews/human/records/video', {
        params,
    });
};

// 人工待审记录列表
export const humanRecordsPostTodoApi = () => {
    return axios<PageResType<HumanRecordsRecordResult<PostContent>>>('/v1/reviews/human/records/todo/post', {
        method: 'get',
    });
};

// 获取meme待审列表
export const humanRecordsMemeTodoApi = () => {
    return axios<PageResType<HumanRecordsRecordResult<MemecoinContent>>>('/v1/reviews/human/records/todo/meme', {
        method: 'get',
    });
};

// 获取短视频待审列表
export const humanRecordsShortsTodoApi = () => {
    return axios<PageResType<HumanRecordsRecordResult<ShortsContent>>>('/v1/reviews/human/records/todo/video', {
        method: 'get',
    });
};

// /v1/reviews/human/records/remain-count
export const humanRecordsRemainCountApi = (type: string) => {
    return axios<ResType<number>>('/v1/reviews/human/records/remain-count', {
        method: 'get',
        params: {
            type,
        },
    });
};

// /v1/reviews/human/records/post
export const humanRecordsPostUpdateApi = (params: HumanRecordsUpdateParams) => {
    return axios<ResType<null>>('/v1/reviews/human/records/post', {
        method: 'post',
        data: params,
    });
};

// /v1/reviews/human/records/meme
export const humanRecordsMemeUpdateApi = (params: HumanRecordsUpdateParams) => {
    return axios<ResType<null>>('/v1/reviews/human/records/meme', {
        method: 'post',
        data: params,
    });
};

// /v1/reviews/human/records/video
export const humanRecordsShortsUpdateApi = (params: HumanRecordsUpdateParams) => {
    return axios<ResType<null>>('/v1/reviews/human/records/video', {
        method: 'post',
        data: params,
    });
};

// 审核状态
export enum PostReviewsMachineEnum {
    approved = 'approved',
    rejected = 'rejected',
    failure = 'failure',
    suspected = 'suspected',
    pending = 'pending',
}

export enum PostReviewsExecutionEnum {
    approved = 'approved',
    rejected = 'rejected',
    manual = 'manual',
}

export enum PostReviewsSuggestionEnum {
    normal = 0,
    suspected = 1,
    rejected = 2,
}

export const PostReviewsSuggestionMap = {
    [PostReviewsSuggestionEnum.normal]: 'normal',
    [PostReviewsSuggestionEnum.suspected]: 'suspected',
    [PostReviewsSuggestionEnum.rejected]: 'rejected',
};

// 场景
export enum PostReviewsType {
    comment = 'comment',
    message = 'message',
    post = 'post',
    shorts = 'shorts',
    user = 'user',
}

// 标签类型
export enum ModerationLabelsType {
    text = 100,
    image = 200,
    audio = 300,
    video = 400,
}

export enum ModerationLabelsTypeText {
    text = 'text',
    image = 'image',
    audio = 'audio',
    video = 'video',
}

export const ModerationLabelsTypeMap = {
    [ModerationLabelsTypeText.text]: ModerationLabelsType.text,
    [ModerationLabelsTypeText.image]: ModerationLabelsType.image,
    [ModerationLabelsTypeText.audio]: ModerationLabelsType.audio,
    [ModerationLabelsTypeText.video]: ModerationLabelsType.video,
};

// 帖子举报Api参数
export type PostReviewsRequestsParams = {
    /**
     * 帖子创建结束搜索时间
     */
    created_end?: string;
    /**
     * 帖子创建起始搜索时间
     */
    created_start?: string;
    /**
     * 每页条数
     */
    size?: number;
    /**
     * 页码
     */
    page?: number;
    /**
     * 帖子ID
     */
    post_id?: string;
    /**
     * 检测结果
     */
    status_machine?: PostReviewsMachineEnum;
    /**
     * 执行结果
     */
    action_result?: PostReviewsExecutionEnum;
    /**
     * 发布者UID
     */
    user_id?: string;
    /**
     * 文本标签
     */
    text_label?: string;
    /**
     * 图片标签
     */
    image_label?: string;
    /**
     * 音频标签
     */
    audio_label?: string;
    /**
     * 视频标签
     */
    video_label?: string;
};

export type MemecoinReviewRequestsParams = {
    /**
     * 名称
     */
    name?: string;
    /**
     * 符号
     */
    symbol?: string;
    /**
     * 发布者UID
     */
    user_id?: string;
    /**
     * 发布者名称
     */
    username?: string;
    /**
     * 发布者昵称
     */
    nickname?: string;
    /**
     * 文本标签
     */
    text_label?: string[];
    /**
     * 图片标签
     */
    image_label?: string[];
    /**
     * 审核状态
     */
    status_machine?: PostReviewsMachineEnum;
    /**
     * 执行结果
     */
    action_result?: PostReviewsExecutionEnum;
    /**
     * 创建起始时间
     */
    created_start?: string;
    /**
     * 创建结束时间
     */
    created_end?: string;
    /**
     * 每页条数
     */
    size?: number;
    /**
     * 页码
     */
    page?: number;
};

/**
 * PostContent
 */
export interface PostContent {
    audios?: Audios;
    audio?: Audios;
    body?: string;
    debate?: Debate;
    uploaded_materials?: string[];
}

export interface MemecoinContent {
    /**
     * 名称
     */
    name?: string;
    /**
     * 符号
     */
    symbol?: string;
    /**
     * 描述
     */
    description?: string;
    /**
     * 图片URL
     */
    image_url?: string;
    /**
     * X
     */
    x?: string;
    /**
     * Telegram
     */
    telegram?: string;
    /**
     * 网站
     */
    website?: string;
    /**
     * 组
     */
    socrates_group?: string;
}

export interface ShortsContent {
    /**
     * 标题
     */
    title?: string;
    /**
     * 正文
     */
    description?: string;
    /**
     * 辩题
     */
    debate?: Debate;
    /**
     * 视频URL
     */
    source_url?: string;
}

export interface MemecoinReviewRecordResult {
    /**
     * 名称
     */
    name: string;
    /**
     * 符号
     */
    symbol: string;
    /**
     * 描述
     */
    description: string;
    /**
     * 图片URL
     */
    image_url: string;
    /**
     * 创建者
     */
    creator: string;
    /**
     * 索罗斯组
     */
    socrates_group: string;
    /**
     * X
     */
    x: string;
    /**
     * Telegram
     */
    telegram: string;
    /**
     * 网站
     */
    website: string;
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * 用户信息
     */
    user_info: {
        username: string;
        nickname: string;
        id: string;
    };
}

export interface Audios {
    text: string;
    url: string;
}

export interface Debate {
    against_text: string;
    for_text: string;
    title: string;
}

export interface MachineAntispam {
    name?: string;
    label?: number;
    secondLabel?: number;
    thirdLabel?: number;
    resultType?: number;
    status?: number;
    suggestion?: number;
    url?: string;
    dataId?: string;
}

export interface MachineApproved {
    dataId: string;
    dealingCount: number;
    taskId: string;
}
export interface MachineResultResponse {
    result: { antispam: MachineAntispam; dataId?: string };
}

/**
 * MachineResult
 */
export interface MachineResult {
    provider: string;
    reason: string;
    request: any[] | boolean | number | number | { [key: string]: any } | null | string;
    response: MachineResultResponse;
    reviewed_at: string;
    status: string;
    type: ModerationLabelsTypeText;
}

export type PostReviewsRecordResult = {
    /**
     * 业务id，帖子id评论id等
     */
    biz_id: string;
    /**
     * 内容
     */
    content: PostContent;
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * ID 编号
     */
    id: string;
    /**
     * 通知时间
     */
    notified_at?: null | string;
    /**
     * 原因
     */
    reason: string;
    /**
     * 请求时间
     */
    requested_at: string;
    /**
     * 人工审核结果
     */
    result_human?: any[] | boolean | number | number | { [key: string]: any } | null | string;
    /**
     * 机器审核结果
     */
    result_machine?: MachineResult[];
    /**
     * 审核时间
     */
    reviewed_at: string;
    /**
     * 审核结果，'approved' | 'rejected'
     */
    status_machine: PostReviewsMachineEnum;
    /**
     * 执行结果
     */
    status_execution: PostReviewsExecutionEnum;
    /**
     * 'comment' | 'message' | 'post' | 'shorts' | 'user'
     */
    type: PostReviewsType;
    /**
     * 更新时间
     */
    updated_at: string;
    /**
     * 发布者UID
     */
    user_id: string;
};

/**
 * ReviewConfig
 */
export interface ReviewConfig {
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * ID 编号
     */
    id: string;
    /**
     * 操作人账号ID
     */
    operator_account_id: string;
    /**
     * 审核
     */
    approved: PostReviewsExecutionEnum;
    /**
     * 拒绝
     */
    rejected: PostReviewsExecutionEnum;
    /**
     * 嫌疑
     */
    suspected: PostReviewsExecutionEnum;
    /**
     * 失败
     */
    failure: PostReviewsExecutionEnum;
    /**
     * 类型
     */
    type: string;
    /**
     * 更新时间
     */
    updated_at: string;
}

export type PostReviewsConfigUpdateParams = {
    approved: string;
    rejected: string;
    suspected: string;
};

/**
 * ModerationLabelsRequestsParams
 */
export type ModerationLabelsRequestsParams = {
    type: ModerationLabelsType;
    language: string;
};

export type ModerationSubLabels = {
    businessId: string;
    businessType: number[];
    code: string;
    createTime: number;
    depth: number;
    name: string;
    subLabels: ModerationSubLabels[];
    type: number;
};

export type ModerationLabels = {
    name: string;
    label: string;
    subLabels?: ModerationSubLabels[];
};

export type ModerationPictureLabel = {
    label: number;
    level: number;
    rate: number;
};

export interface HumanRecordsRequestsParams {
    /**
     * 类型（post/shorts）
     */
    type?: string;

    /** 帖子 ID */
    post_id?: string;

    /** 发布者 ID */
    user_id?: string;

    /** 正文 */
    content?: string;

    /** 辩题 */
    debate?: string;

    /** 帖子类型 (debate/image/audio/video) */
    post_type?: string;

    /** 发布搜索起始时间 */
    created_start?: string;

    /** 发布搜索结束时间 */
    created_end?: string;

    /** 操作搜索起始时间 */
    operated_start?: string;

    /** 操作搜索结束时间 */
    operated_end?: string;

    /** 审核结果 (approved/rejected) */
    action_result?: string;

    /** 违规类型 */
    violation_type?: string;

    /** 操作人 ID */
    operator?: string;

    /** 操作搜索起始时间 */
    operate_start?: string;

    /** 操作搜索结束时间 */
    operate_end?: string;

    /** 每页条数 */
    size?: number;

    /** 页码 */
    page?: number;
}

export interface HumanRecordsRecordResult<T> {
    /**
     * 业务id，帖子id评论id等
     */
    biz_id: string;
    /**
     * 内容
     */
    content: T;
    /**
     * 创建时间
     */
    created_at: string;
    /**
     * ID 编号
     */
    id: string;
    /**
     * 通知时间
     */
    notified_at?: null | string;
    /**
     * 原因
     */
    reason: string;
    /**
     * 请求时间
     */
    requested_at: string;
    /**
     * 人工审核结果
     */
    result_human?: any[] | boolean | number | number | { [key: string]: any } | null | string;
    /**
     * 机器审核结果
     */
    result_machine?: MachineResult[];
    /**
     * 审核时间
     */
    reviewed_at: string;
    /**
     * 审核结果，'approved' | 'rejected'
     */
    status_machine: PostReviewsMachineEnum;
    /**
     * 执行结果
     */
    status_execution: PostReviewsExecutionEnum;
    /**
     * 'comment' | 'message' | 'post' | 'shorts' | 'user'
     */
    type: PostReviewsType;
    /**
     * 更新时间
     */
    updated_at: string;
    /**
     * 发布者UID
     */
    user_id: string;
}

export type HumanRecordsUpdateReview = {
    id: string;
    status: string;
    violation_type: string;
    reason: string;
};

export type HumanRecordsUpdateParams = {
    reviews: HumanRecordsUpdateReview[];
};
