/**
 * Props
 */
type Props = {
    type: string;
    reason: string | null | undefined;
};

const ComponentTableCellReason: React.FC<Props> = ({ reason }) => {
    if (!reason) {
        return '-';
    }
    return <div>{reason}</div>;
};

export default ComponentTableCellReason;
