import { useRequest, useSetState } from 'ahooks';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Select } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { get, isEmpty, omitBy } from 'lodash';

/**
 * Components
 */
import TableCellMedia from '../components/table-cell-media';
import ComponentTableCellReason from '../components/table-cell-reason';
import ComponentSearchFormSelectOperator from '../components/search-form-select-operator';
import ComponentTableCellDebate from '@/components/table-cell-debate';

/**
 * APIs
 */
import { shortsReportReordsApi } from '@/services/report';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { FormProps, PaginationProps } from 'antd';
import type { PostReportRecordResult, ShortsReportReordsApiParams } from '@/services/report';

/**
 * Constants
 */
import ReportTypeENUM from '../components/report-type-enum';

/**
 * State
 */
type State = {
    dataSource: PostReportRecordResult[];
};

const PageReportModerationRecordsShorts: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });
    const intl = useIntl();

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<ShortsReportReordsApiParams>({
        operation_date: [dayjs(), dayjs()],
    });

    const {
        loading: loadingShortsReportReordsApi,
        refresh: refreshShortsReportReordsApi,
        run: runShortsReportReordsApi,
    } = useRequest(
        (params: ShortsReportReordsApiParams) => {
            if (params.operation_date && params.operation_date?.length) {
                params.updated_start = params.operation_date[0].startOf('d').format();
                params.updated_end = params.operation_date[1].endOf('d').format();
            }
            if (params.page) {
                params.page = `${params.page}`;
            }
            if (params.size) {
                params.size = `${params.size}`;
            }
            params.operation_date = undefined;
            return shortsReportReordsApi({ ...omitBy(params, isEmpty) });
        },
        {
            manual: true,
            onSuccess: ({ data: { code, data, msg } }) => {
                if (code !== 0) {
                    return message.error(msg);
                }

                setState({
                    dataSource: data.list,
                });
                setPagination({
                    total: data.meta.total,
                });
            },
        },
    );
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<ShortsReportReordsApiParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (typeof values.status === 'string') {
                values.status = [values.status];
            }

            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
                status: values.status,
            });
        },
    };

    const proTableProps: ProTableProps<PostReportRecordResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                title: 'No.',
                width: 50,
                fixed: 'left',
                hideInSearch: true,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.reportId" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedVideoContent', 'video_id'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.shortsId" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedUserInfo', 'user_id'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postAccUid" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedVideoContent', 'content', 'debate', 'title'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.title" />,
                width: 200,
            },
            {
                dataIndex: ['content', 'reportedVideoContent', 'content', 'body'],
                title: <FormattedMessage id="rm.t.c.content" />,
            },
            {
                dataIndex: '_debate_users',
                title: <FormattedMessage id="rm.t.c.debateUsers" />,
                render: (_, entity: any) => (
                    <ComponentTableCellDebate
                        forText={get(entity, 'content.reportedVideoContent.content.debate.for_text', '')}
                        againstText={get(entity, 'content.reportedVideoContent.content.debate.against_text', '')}
                    />
                ),
                width: 200,
            },
            {
                dataIndex: '_media',
                title: <FormattedMessage id="rm.t.c.video" />,
                render: (_, entity: any) => (
                    <TableCellMedia list={get(entity, 'content.reportedVideoContent.content.uploaded_materials', [])} />
                ),
                width: 320,
            },
            {
                dataIndex: 'report_type',
                title: <FormattedMessage id="rm.t.c.reportType" />,
                width: 120,
                valueEnum: ReportTypeENUM,
            },
            {
                dataIndex: 'reason',
                title: <FormattedMessage id="rm.t.c.otherReason" />,
                render: (_, entity) => <ComponentTableCellReason type={entity.report_type} reason={entity.reason} />,
                width: 120,
            },
            {
                dataIndex: ['content', 'reportUserInfo', 'user_id'],
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.reporter-UID" />,
                width: 200,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="rm.t.c.reportTime" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="rm.t.c.review-result" />,
                valueEnum: {
                    normal: <FormattedMessage id="rm.b.normal" />,
                    violate: <FormattedMessage id="rm.b.violate" />,
                },
                width: 120,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
        ],
        dataSource: state.dataSource,
        loading: loadingShortsReportReordsApi,
        options: {
            reload: refreshShortsReportReordsApi,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 2650,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runShortsReportReordsApi({
            ...payload,
            page: pagination.current as any,
            size: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.reportId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="reportd_uid" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postAccUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="report_uid" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.reporter-UID' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="video_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.shortsId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'rm.b.normal' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'rm.b.violate' }),
                                        value: 'violate',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="rm.t.c.review-result" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="operator" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Reports_Moderation-Records_Shorts" />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                            initialValue={[dayjs(), dayjs()]}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingShortsReportReordsApi}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageReportModerationRecordsShorts;
