import { Checkbox, Popconfirm, Space, Spin, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

/**
 * APIs
 */
import { useState } from 'react';

/**
 * Constants
 */
export const ResetItemMap: Record<string, React.ReactNode> = {
    nickname: <FormattedMessage id="rm.t.c.nickName" />,
    name: <FormattedMessage id="rm.t.c.name" />,
    avatar: <FormattedMessage id="rm.t.c.avatar" />,
    bio: <FormattedMessage id="rm.t.c.userInfo" />,
};
const ResetItemList = Object.entries(ResetItemMap).map((key) => ({
    value: key[0],
    label: key[1],
}));

/**
 * Props
 */
type Props = {
    entity: any;
    sureCallback: (id: string | number, reset_items: string[]) => void;
    loading: boolean;
};
const ComponentAccountReportBtn: React.FC<Props> = ({ entity, sureCallback, loading }) => {
    /**
     * States
     */
    const [checkbox, setCheckbox] = useState([]);

    return (
        <Popconfirm
            key="delete"
            icon={null}
            title={null}
            okButtonProps={{
                disabled: checkbox.length === 0,
            }}
            description={() => (
                <Checkbox.Group value={checkbox} onChange={setCheckbox} style={{ width: 200 }}>
                    <Space direction="vertical">
                        {ResetItemList.map((v) => {
                            return <Checkbox value={v.value}>{v.label}</Checkbox>;
                        })}
                    </Space>
                </Checkbox.Group>
            )}
            onConfirm={async () => {
                await sureCallback(entity.id, checkbox);
            }}
            onOpenChange={(open) => !open && setCheckbox([])}
        >
            <Spin spinning={loading} size="small">
                <Typography.Link type="danger">
                    <FormattedMessage id="rm.b.violate" />
                </Typography.Link>
            </Spin>
        </Popconfirm>
    );
};

export default ComponentAccountReportBtn;
