import React from 'react';

interface HighlightTextProps {
    text: string;
    keywords: string[];
}

// URL相关的常量和工具函数
const URL_REGEX = /\b((https?:\/\/)?[a-z0-9.-]+\.[a-z]{2,}(\/[^\s]*)?(\?[^\s]*)?)/gi;

const processTextWithUrls = (text: string) => {
    const matches = Array.from(text.matchAll(URL_REGEX));
    if (!matches.length) return text;

    const result = [];
    let lastIndex = 0;

    matches.forEach((match) => {
        // 添加URL前的文本
        if (match.index! > lastIndex) {
            result.push(text.substring(lastIndex, match.index));
        }

        // 添加URL链接
        result.push(
            <a
                key={`url-${match.index}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#1890ff', textDecoration: 'underline', wordBreak: 'break-all' }}
            >
                {match[0]}
            </a>,
        );

        lastIndex = match.index! + match[0].length;
    });

    // 添加最后一个URL后的文本
    if (lastIndex < text.length) {
        result.push(text.substring(lastIndex));
    }

    return result;
};

const HighlightText: React.FC<HighlightTextProps> = ({ text, keywords }) => {
    if (!keywords?.length) {
        // 仅处理URL
        return <span>{processTextWithUrls(text)}</span>;
    }

    // 关键词匹配正则
    const keywordRegex = new RegExp(`(${keywords.join('|')})`, 'gi');

    // 使用 processTextWithUrls 处理文本
    const urlProcessedContent = processTextWithUrls(text);

    // 处理关键词高亮
    const parts = React.Children.map(urlProcessedContent, (child, index) => {
        if (React.isValidElement(child)) {
            // 如果是 URL 链接元素，直接返回
            return child;
        }

        // 处理普通文本的关键词高亮
        if (typeof child === 'string') {
            return child.split(keywordRegex).map((part, kwIndex) => {
                if (keywordRegex.test(part)) {
                    return (
                        <span key={`kw-${index}-${kwIndex}`} style={{ color: 'red' }}>
                            {part}
                        </span>
                    );
                }
                return part;
            });
        }

        return child;
    });

    return <span>{parts}</span>;
};

export default HighlightText;
