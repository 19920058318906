import { Cascader } from 'antd';

/**
 * Stores
 */
import { useReviewLabelsStore } from '@/store/review-labels';

/**
 * APis
 */
// import { gModerationLabels } from '../services/reviews';

/**
 * Types
 */
import type { ModerationLabelsType, ModerationLabels, ModerationSubLabels } from '../services/reviews';

type ValueType = string[][] | undefined;

type OperatorProps = {
    id?: string;
    value?: ValueType;
    onChange?: (value: string[][], selectOptions: FormattedLabels[]) => void;
    type: ModerationLabelsType;
    placeholder?: React.ReactNode;
};

type FormattedLabels = {
    label: string;
    value: string;
    children?: FormattedLabels[];
};

/**
 *
 * @param param0
 * @returns
 */

const formatSubLabels = (labels: ModerationSubLabels[]): FormattedLabels[] => {
    return labels.map((item) => ({
        label: item.name,
        value: item.code,
        children: item.subLabels ? formatSubLabels(item.subLabels) : [],
    }));
};

const formatLabels = (labels: ModerationLabels[]): FormattedLabels[] => {
    return labels.map((item) => {
        const { name, label, subLabels, ...rest } = item;
        const formattedItem: FormattedLabels = {
            label: name,
            value: label,
            ...rest,
        };

        if (subLabels && subLabels.length > 0) {
            formattedItem.children = formatSubLabels(subLabels);
        }

        return formattedItem;
    });
};

const ComponentSearchModerationLabel: React.FC<OperatorProps> = ({
    id,
    value = undefined,
    onChange,
    type,
    placeholder,
}) => {
    /**
     * States
     */
    const { labels } = useReviewLabelsStore((state) => ({
        labels: formatLabels(Array.isArray(state.labels?.[type]) ? state.labels?.[type] : []),
    }));

    return (
        <Cascader
            id={id}
            value={value}
            allowClear={true}
            multiple={true}
            options={labels}
            placeholder={placeholder}
            style={{ width: 200 }}
            onChange={onChange}
        />
    );
};

export default ComponentSearchModerationLabel;
