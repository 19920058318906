export default {
    rm: {
        b: {
            normal: 'Normal',
            violate: 'Violation',
            multNormal: 'Bulk Normal',
        },
        t: {
            c: {
                reportId: 'Report ID',
                postUid: 'Post ID',
                formPostUid: 'Post ID',
                commentId: 'Reply ID',
                commentContent: 'Content',
                postAccUid: 'Creator UID',
                content: 'Content',
                postImg: 'Image/Video',
                postAudio: 'Audio',
                nickName: 'Nickname',
                name: 'Username',
                avatar: 'Avatar',
                userInfo: 'Bio',
                reportType: 'Type',
                otherReason: 'Other',
                reportTime: 'Reported At',
                'reporter-UID': 'Reporter UID',
                'review-result': 'Result',
                debate: 'Debate',
                debateUsers: 'For&Against',
                shortsId: 'Shorts ID',
                video: 'Video',
                formShortsId: '所属短视频ID',
                image: 'Image',
            },
        },
        reportType: {
            other: 'Other',

            Bullying: 'Bulling&harassment',
            HateSpeech: 'Hate speech and promotion of hateful ideologies',
            Scam: 'Scam or fraudulent information',
            Spam: 'Spam',
            Racism: 'Racism',
            Nudity: 'Nudity of sexual content',
            PlatformSecurity: 'Platform security',
            FalseInformantion: 'False information',
            Violent: 'Violent or dangerous organization',
            IntellectualPropertyRights: 'infringement of intellectual property rights',
            Other: 'Other',
        },
        p: {},
    },
};
